import { createEntityAdapter, createSelector, createSlice, current, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DicomRule, DicomAttributeRule, ModelVisibility } from "../global-types/customization-types";
import { DoseCustomizationEntities, DoseCustomizationOutput, DoseRoi, DoseTarget } from "./dose-types";
import { BackendValidationErrorViewModel, DoseFormValidationError } from "../global-types/store-errors";
import { naturalSort } from "../../util/sort";




// entity adapters (see https://redux-toolkit.js.org/api/createEntityAdapter) for dose customization types
export const modelAdapter = createEntityAdapter<Model>();
export const customizationBaseAdapter = createEntityAdapter<CustomizationBase>();
export const doseOutputAdapter = createEntityAdapter<DoseCustomizationOutput>();
export const outputMetadataAdapter = createEntityAdapter<OutputMetadataItem>();
export const doseRoiAdapter = createEntityAdapter<DoseRoi>();
export const doseTargetAdapter = createEntityAdapter<DoseTarget>();
export const aeTitleRuleAdapter = createEntityAdapter<AeTitleRule>();
export const dicomRuleAdapter = createEntityAdapter<DicomRule>();
export const dicomAttributeRuleAdapter = createEntityAdapter<DicomAttributeRule>();

// entity adapter selectors -- these should not be exported/used directly but rather through the slice selectors
export const modelAdapterSelectors = modelAdapter.getSelectors((state: DoseSliceState) => state.models);
export const customizationBaseAdapterSelectors = customizationBaseAdapter.getSelectors((state: DoseSliceState) => state.customizationBases);
export const doseOutputAdapterSelectors = doseOutputAdapter.getSelectors((state: DoseSliceState) => state.doseOutputs);
export const outputMetadataAdapterSelectors = outputMetadataAdapter.getSelectors((state: DoseSliceState) => state.outputMetadata);
export const doseRoiAdapterSelectors = doseRoiAdapter.getSelectors((state: DoseSliceState) => state.doseRois);
export const doseTargetAdapterSelectors = doseTargetAdapter.getSelectors((state: DoseSliceState) => state.doseTargets);
export const aeTitleRuleAdapterSelectors = aeTitleRuleAdapter.getSelectors((state: DoseSliceState) => state.aeTitleRules);
export const dicomRuleAdapterSelectors = dicomRuleAdapter.getSelectors((state: DoseSliceState) => state.dicomRules);
export const dicomAttributeRuleAdapterSelectors = dicomAttributeRuleAdapter.getSelectors((state: DoseSliceState) => state.dicomAttributeRules);

/** An entity adapter for CRUD operations for backend-based customization validation errors */
export const backendValidationErrorAdapter = createEntityAdapter<BackendValidationErrorViewModel>();
/** An entity adapter for CRUD operations for UI-supplied customization validation errors */
export const formValidationErrorAdapter = createEntityAdapter<DoseFormValidationError>();


// validation error selectors
export const customizationValidationErrorAdapterSelectors = backendValidationErrorAdapter.getSelectors((state: DoseSliceState) => state.backendValidationErrors);
export const formValidationErrorAdapterSelectors = formValidationErrorAdapter.getSelectors((state: DoseSliceState) => state.formValidationErrors);


export type DoseSliceState = {
    /** In-memory CRUD-based normalized data structure for customization objects modelling dose models. */
    models: EntityState<Model, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization base objects. */
    customizationBases: EntityState<CustomizationBase, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization outputs. */
    doseOutputs: EntityState<DoseCustomizationOutput, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling customization metadata. */
    outputMetadata: EntityState<OutputMetadataItem, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling dose ROI customizations. */
    doseRois: EntityState<DoseRoi, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling dose targets. */
    doseTargets: EntityState<DoseTarget, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling AE Title-based model selection rules. */
    aeTitleRules: EntityState<AeTitleRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM-based model selection rules. */
    dicomRules: EntityState<DicomRule, string>,
    /** In-memory CRUD-based normalized data structure for customization objects modelling DICOM attribute rules within DICOM-based model selection rules. */
    dicomAttributeRules: EntityState<DicomAttributeRule, string>,

    // these are for storing data directly from the backend, so any local modifications can be easily reverted back to them

    /** Original, unmodified directly-from-backend versions of dose model customization data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalModels: EntityState<Model, string>;
    /** Original, unmodified directly-from-backend versions of dose customization base data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalCustomizationBases: EntityState<CustomizationBase, string>;
    /** Original, unmodified directly-from-backend versions of dose customization output data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDoseOutputs: EntityState<DoseCustomizationOutput, string>;
    /** Original, unmodified directly-from-backend versions of dose customization metadata objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalOutputMetadata: EntityState<OutputMetadataItem, string>;
    /** Original, unmodified directly-from-backend versions of dose ROI customization objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDoseRois: EntityState<DoseRoi, string>;
    /** Original, unmodified directly-from-backend versions of dose target customization objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDoseTargets: EntityState<DoseTarget, string>;
    /** Original, unmodified directly-from-backend versions of dose AE Title rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalAeTitleRules: EntityState<AeTitleRule, string>;
    /** Original, unmodified directly-from-backend versions of dose DICOM rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDicomRules: EntityState<DicomRule, string>;
    /** Original, unmodified directly-from-backend versions of dose DICOM attribute rule data objects. These are stored 
     * mainly so reverting changes back to original data is easy.
     */
    originalDicomAttributeRules: EntityState<DicomAttributeRule, string>;


    /** Dose customization validation errors coming from backend. */
    backendValidationErrors: EntityState<BackendValidationErrorViewModel, string>,
    /** Dose customization validation errors generated in UI (in "forms"). */
    formValidationErrors: EntityState<DoseFormValidationError, string>,

    /** An error that occurred when fetching contouring customizations, or null. */
    customizationFetchError: string | null,

};





export const initialState: DoseSliceState = {
    models: modelAdapter.getInitialState(),
    customizationBases: customizationBaseAdapter.getInitialState(),
    doseOutputs: doseOutputAdapter.getInitialState(),
    outputMetadata: outputMetadataAdapter.getInitialState(),
    doseRois: doseRoiAdapter.getInitialState(),
    doseTargets: doseTargetAdapter.getInitialState(),
    aeTitleRules: aeTitleRuleAdapter.getInitialState(),
    dicomRules: dicomRuleAdapter.getInitialState(),
    dicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    originalModels: modelAdapter.getInitialState(),
    originalCustomizationBases: customizationBaseAdapter.getInitialState(),
    originalDoseOutputs: doseOutputAdapter.getInitialState(),
    originalOutputMetadata: outputMetadataAdapter.getInitialState(),
    originalDoseRois: doseRoiAdapter.getInitialState(),
    originalDoseTargets: doseTargetAdapter.getInitialState(),
    originalAeTitleRules: aeTitleRuleAdapter.getInitialState(),
    originalDicomRules: dicomRuleAdapter.getInitialState(),
    originalDicomAttributeRules: dicomAttributeRuleAdapter.getInitialState(),

    backendValidationErrors: backendValidationErrorAdapter.getInitialState(),
    formValidationErrors: formValidationErrorAdapter.getInitialState(),

    customizationFetchError: null,

};




/** Redux store slice for interacting with dose+ configuration and customization. */
const doseSlice = createSlice({
    name: 'dose',
    initialState,
    reducers: {
        /**
         * Sets current dose model customization entities to provided values. This is used when initializing, swapping, or
         * resetting entire model customizations loaded into memory.
         * @param action.customizations All contouring-related model customization entities to load into memory. If set to undefined then
         * in-model entities are reset to default empty values.
         * @param action.errorMessage Optional error message.
         */
        modelCustomizationsSet(state, action: PayloadAction<{ customizations: DoseCustomizationEntities | null, errorMessage?: string }>) {
            const { customizations, errorMessage } = action.payload;

            if (customizations !== null) {
                // set everything according to input
                modelAdapter.setAll(state.models, customizations.models);
                customizationBaseAdapter.setAll(state.customizationBases, customizations.customizationBases);
                doseOutputAdapter.setAll(state.doseOutputs, customizations.doseOutputs);
                outputMetadataAdapter.setAll(state.outputMetadata, customizations.outputMetadata);
                doseRoiAdapter.setAll(state.doseRois, customizations.doseRois);
                doseTargetAdapter.setAll(state.doseTargets, customizations.doseTargets);
                aeTitleRuleAdapter.setAll(state.aeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.dicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, customizations.dicomAttributeRules);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, customizations.models);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizations.customizationBases);
                doseOutputAdapter.setAll(state.originalDoseOutputs, customizations.doseOutputs);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, customizations.outputMetadata);
                doseRoiAdapter.setAll(state.originalDoseRois, customizations.doseRois);
                doseTargetAdapter.setAll(state.originalDoseTargets, customizations.doseTargets);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, customizations.aeTitleRules);
                dicomRuleAdapter.setAll(state.originalDicomRules, customizations.dicomRules);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, customizations.dicomAttributeRules);
            } else {
                // set everything to default values
                modelAdapter.setAll(state.models, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.customizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                doseOutputAdapter.setAll(state.doseOutputs, doseOutputAdapter.getInitialState().entities as Record<string, DoseCustomizationOutput>);
                outputMetadataAdapter.setAll(state.outputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);
                doseRoiAdapter.setAll(state.doseRois, doseRoiAdapter.getInitialState().entities as Record<string, DoseRoi>);
                doseTargetAdapter.setAll(state.doseTargets, doseTargetAdapter.getInitialState().entities as Record<string, DoseTarget>);
                aeTitleRuleAdapter.setAll(state.aeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.dicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.dicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);

                // set original collections, also, so we can revert changes to the actual collection with ease
                modelAdapter.setAll(state.originalModels, modelAdapter.getInitialState().entities as Record<string, Model>);
                customizationBaseAdapter.setAll(state.originalCustomizationBases, customizationBaseAdapter.getInitialState().entities as Record<string, CustomizationBase>);
                doseOutputAdapter.setAll(state.originalDoseOutputs, doseOutputAdapter.getInitialState().entities as Record<string, DoseCustomizationOutput>);
                outputMetadataAdapter.setAll(state.originalOutputMetadata, outputMetadataAdapter.getInitialState().entities as Record<string, OutputMetadataItem>);
                doseRoiAdapter.setAll(state.originalDoseRois, doseRoiAdapter.getInitialState().entities as Record<string, DoseRoi>);
                doseTargetAdapter.setAll(state.originalDoseTargets, doseTargetAdapter.getInitialState().entities as Record<string, DoseTarget>);
                aeTitleRuleAdapter.setAll(state.originalAeTitleRules, aeTitleRuleAdapter.getInitialState().entities as Record<string, AeTitleRule>);
                dicomRuleAdapter.setAll(state.originalDicomRules, dicomRuleAdapter.getInitialState().entities as Record<string, DicomRule>);
                dicomAttributeRuleAdapter.setAll(state.originalDicomAttributeRules, dicomAttributeRuleAdapter.getInitialState().entities as Record<string, DicomAttributeRule>);
            }

            state.customizationFetchError = errorMessage !== undefined ? errorMessage : null;
            if (errorMessage === undefined) {
                // clear any errors
                backendValidationErrorAdapter.removeAll(state.backendValidationErrors);
                formValidationErrorAdapter.removeAll(state.formValidationErrors);

                // calculate initial form validation errors
                for (const outputId of state.doseOutputs.ids) {
                    console.log('TODO_1: calculate initial form validation errors')
                    // performFormValidationForOutput(state, outputId as string);
                }
            }
        },
    },
    selectors: {

        /** Returns all dose models that have their visibility set as Always Hidden. */
        selectUserHiddenModels: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.visibility === ModelVisibility.AlwaysHidden)),

        /** Returns all dose models that are not in current user's license. */
        selectNonLicensedModels: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => !m.isAvailable)),

        /** Returns all dose models that are deprecated. */
        selectDeprecatedModels: createSelector(
            (state: DoseSliceState): Model[] => localSelectors.selectModels(state),
            models => models.filter(m => m.isDeprecated)),

        // ENTITY ADAPTER SELECTORS:
        // for all supported createEntityAdapter selectors see https://redux-toolkit.js.org/api/createEntityAdapter#selector-functions

        selectModels: (state): Model[] => modelAdapterSelectors.selectAll(state),
        selectModelsInAlphabeticalOrder: createSelector(
            [(state: DoseSliceState): Model[] => localSelectors.selectModels(state)],
            models => naturalSort(models, 'modelName')),

        selectCustomizationBases: (state) => customizationBaseAdapterSelectors.selectAll(state),
        selectCustomizationBasesInAlphabeticalOrder: createSelector(
            [(state: DoseSliceState): CustomizationBase[] => localSelectors.selectCustomizationBases(state)],
            bases => naturalSort(bases, 'customizationName')),

        selectOutputEntities: (state) => doseOutputAdapterSelectors.selectEntities(state),

        selectOutputMetadataEntities: (state) => outputMetadataAdapterSelectors.selectEntities(state),

        selectCustomizationValidationErrorEntities: (state) => customizationValidationErrorAdapterSelectors.selectEntities(state),
    }
});




export const {
    modelCustomizationsSet,

} = doseSlice.actions;

const localSelectors = doseSlice.getSelectors();

export const { getInitialState, selectors: doseSelectors } = doseSlice;

export default doseSlice.reducer;
