import React, { FormEvent, useCallback } from 'react';
import { TextInput } from '@patternfly/react-core';

import './text-override-field.css';
import InputLabel from './input-label';

interface TextOverrideFieldProps {
    defaultText: string,
    textValue: string,
    onChange: (value: string) => void,
    id: string,
    /** Text to show to user when text is overridden. Leave undefined to essentially disable this component's main use. */
    textIsOverriddenHelp?: React.ReactNode,
    validated?: 'default' | 'success' | 'warning' | 'error' | undefined,
    isDisabled?: boolean | undefined;
    className?: string | undefined;
    label?: string;
    allowEmptyField?: boolean;
}

const TextOverrideField = (props: TextOverrideFieldProps) => {
    const { id, defaultText, textValue, onChange, textIsOverriddenHelp, validated, isDisabled, label, allowEmptyField } = props;


    const handleChange = useCallback((_: unknown,  value: string) => {
        onChange(value);
    }, [onChange]);

    const handleBlur = useCallback((evt: FormEvent<HTMLInputElement>) => {
        // revert an empty text field back to default value when focus leaves
        if (allowEmptyField === false && !textValue) {
            handleChange(evt, defaultText);
        }
    }, [textValue, defaultText, handleChange, allowEmptyField]);

    const currentValue = textValue !== undefined && textValue !== null ? textValue : defaultText;
    const className = `text-override-field ${props.className || ''}`;

    return (
        <div className={className}>
            <InputLabel label={label} useRef>
                <TextInput
                    className="text-override-input"
                    type="text"
                    id={id}
                    onChange={handleChange}
                    value={currentValue}
                    validated={validated}
                    onBlur={handleBlur}
                    isDisabled={isDisabled === true}
                />
            </InputLabel>
            {textIsOverriddenHelp && currentValue && currentValue !== defaultText && (
                <div className="text-is-overridden">{textIsOverriddenHelp}</div>
            )}
        </div>
    );
}

export default TextOverrideField;