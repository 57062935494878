import React, { ChangeEvent, DragEvent, useState } from 'react';
import { Button, FileUpload, Alert, Modal, ModalVariant, DropEvent } from '@patternfly/react-core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { readModelCustomizationJsonFile } from '../../store/contouring/contouring-helpers';
import { StoreState } from '../../store/store';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { modelCustomizationExported, modelCustomizationImported, modelCustomizationImportFailed } from '../../store/contouring/contouringSlice';


const ConfigurationImportExport = () => {
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);  
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isImportingCompletedTextVisible, setIsImportingCompletedTextVisible] = useState(false);
    const [showCustomizationImportFile, setShowCustomizationImportFile] = useState<File | undefined>(undefined);
    const [showConfirmFileImport, setShowConfirmFileImport] = useState(false);

    const importModelCustomizationFailureSelect = useSelector((state: StoreState) => state.contouring.customizationImportExportError);
    const handleImportFileChange = async (file: File) => {
        await handleImportUserData(file);
        setShowConfirmFileImport(false);
    };

    const handleConfirmImportFileChange = async (
        _: unknown,  file: File
    ) => {
        setShowCustomizationImportFile(file);
        setShowConfirmFileImport(true);
    };

    const handleClear = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowCustomizationImportFile(undefined);
    };

    const handleExportUserData = async () => {
        if (!currentTarget) {
            return;
        }
        dispatch(modelCustomizationExported(currentTarget));
    };

    const handleImportUserData = async (json: any) => {
        try {
            const readFile = await readModelCustomizationJsonFile(json);
            dispatch(modelCustomizationImported(readFile));
            setIsImportingCompletedTextVisible(true);
        } catch (error) {
            setIsImportingCompletedTextVisible(false);
            dispatch(modelCustomizationImportFailed('Error importing configuration. Please ensure the imported data is valid.'));
        }
    };

    return (
        <>
            {isImportingCompletedTextVisible && (
                <div className="configuration-reset-completed-box">
                    <Alert variant="info" isInline title={t('settings.importConfiguration.success')} />
                </div>
            )}

            {importModelCustomizationFailureSelect && (
                <div className="configuration-reset-completed-box">
                    <Alert
                        variant="danger"
                        isInline
                        title={importModelCustomizationFailureSelect}
                    />
                </div>
            )}

            {currentTarget && (
                <>
                    <div className="setting-item">
                        <span className="label">{t('settings.importConfiguration')}</span>
                        <span className="value">
                            <FileUpload
                                id="daemon-config-file"
                                filename={showCustomizationImportFile?.name || ''}
                                filenamePlaceholder={t('settings.importConfiguration.file.instructions')}
                                title={t('settings.importConfiguration.file.instructions')}
                                onFileInputChange={handleConfirmImportFileChange}
                                onClearClick={handleClear}
                                browseButtonText={t('settings.importConfiguration.file.browse')}
                                clearButtonText={t('settings.importConfiguration.file.clear')}
                            />
                        </span>
                    </div>

                    <div className="setting-item">
                        <span className="label">{t('settings.exportConfiguration')}</span>
                        <span className="value">
                            <Button variant="secondary" onClick={handleExportUserData}>{t('settings.exportConfiguration.button')}</Button>
                        </span>
                    </div>
                </>
            )}

            <Modal
                variant={ModalVariant.small}
                title={t('settings.confirmImport.title')}
                isOpen={showConfirmFileImport && showCustomizationImportFile !== undefined}
                showClose
                onClose={() => setShowConfirmFileImport(false)}
                actions={[
                    <Button key="confirmImport" variant="primary" onClick={() => handleImportFileChange(showCustomizationImportFile!)}>{t('common.ok')}</Button>,
                    <Button key="cancelImport" variant="tertiary" onClick={() => setShowConfirmFileImport(false)}>{t('common.cancel')}</Button>
                ]}
            >{t('settings.confirmImport.message')}
            </Modal>
        </>
    );
};

export default ConfigurationImportExport;
