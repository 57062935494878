import { useSelector } from 'react-redux';


import './../customization/customization-page.css';
import GlobalRoiCustomizationForm from './GlobalRoiCustomizationForm';
import {
	Th,
	Thead,
	Tr
} from '@patternfly/react-table';
import {
	Table
} from '@patternfly/react-table/deprecated';
import { columnIds, columnLangKeys, columnTooltips } from '../customization/contouring/RoiTable';
import { EntityId } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { contouringSelectors } from '../../store/contouring/contouringSlice';

const GlobalRoiCustomizationCollectionForm = () => {

    const { t } = useTranslation();
    const globalRoiIds = useSelector(contouringSelectors.selectGlobalRoiIds);
    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);

    return (
        <div>
            <Table variant='compact' isStickyHeader className="mv-sticky-fix" aria-label='global rois table'>
                <Thead aria-label='header'>
                    <Tr>
                        <Th aria-label='Checkbox'></Th>
                        <Th aria-label='Name'>{t(columnLangKeys[columnIds.name])}</Th>
                        <Th aria-label='Color'>{t(columnLangKeys[columnIds.color])}</Th>
                        <Th aria-label='Operation' info={{ tooltip: columnTooltips[columnIds.operation](deploymentInfo?.operationsManualUrl) }}>{t(columnLangKeys[columnIds.operation])}</Th>
                        <Th aria-label='Interpreted type'>{t(columnLangKeys[columnIds.interpretedType])}</Th>
                        <Th aria-label='FMA Code Value'>{t(columnLangKeys[columnIds.fmaCodeValue])}</Th>
                        <Th aria-label='FMA Code Meaning'>{t(columnLangKeys[columnIds.fmaCodeMeaning])}</Th>
                        <Th aria-label='FMA Code Scheme Designator'>{t(columnLangKeys[columnIds.fmaCodeSchemeDesignator])}</Th>
                        <Th aria-label='Included Models' info={{ tooltip: columnTooltips[columnIds.includedModels]() }}>{t(columnLangKeys[columnIds.includedModels])}</Th>
                        <Th aria-label='Excluded Models' info={{ tooltip: columnTooltips[columnIds.excludedModels]() }}>{t(columnLangKeys[columnIds.excludedModels])}</Th>
                        <Th aria-label='Options'></Th>
                    </Tr>
                </Thead>
                {globalRoiIds.map((rId: EntityId, rowIndex: number) => {
                    return (
                        <GlobalRoiCustomizationForm
                            globalRoiId={rId as string}
                            globalRoiIdList={globalRoiIds as string[]}
                            key={rId}
                            rowIndex={rowIndex}
                        />
                    );
                })}
            </Table>
        </div>
    );
}

export default GlobalRoiCustomizationCollectionForm;
