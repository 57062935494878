import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandableRowContent, Table /* data-codemods */, Tbody, Td, TdProps, Thead, Tr } from '@patternfly/react-table';
import { Button, EmptyState, EmptyStateBody, EmptyStateVariant, } from '@patternfly/react-core';

import CoveredRoi from './CoveredRoi';
import ExcludedRoi from './ExcludedRoi';
import RoiItem from '../customization/contouring/RoiItem';
import { columnCssNames, columnIds, columnLangKeys, RoiTableColumnKey } from '../customization/contouring/RoiTable';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../store/store';
import { contouringSelectors, roiCustomizationIncludedInModelUpdated, roiCustomizationNameUpdated } from '../../store/contouring/contouringSlice';
import { fullRoiDetailsShown } from '../../store/appStatus/appStatusSlice';

interface GlobalRoiCustomizationFormProps {
    globalRoiId: string;
    rowIndex: number;
    globalRoiIdList: string[];
}

const GlobalRoiCustomizationForm = (props: GlobalRoiCustomizationFormProps) => {
    const { globalRoiId, rowIndex, globalRoiIdList } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const globalRoi = useSelector((state: StoreState) => contouringSelectors.selectGlobalRoiById(state, globalRoiId));

    // TODO: not yet supported -- it's not immediately obvious into what state reverting a global roi should
    // const handleUndoStructureChanges = useCallback(() => {
    //     // put itself and the rois it covers and doesn't cover
    //     dispatch(undoRoiCustomizationChanges(globalRoiId, true));
    // }, [dispatch, globalRoiId]);

    const handleRevertName = useCallback(() => {
        dispatch(roiCustomizationNameUpdated({ roiId: globalRoi!.id, name: globalRoi!.operation, isGlobalRoi: true }));
    }, [dispatch, globalRoi]);

    const handleShowFullRoiDetails = useCallback(() => {
        dispatch(fullRoiDetailsShown({ currentRoiId: globalRoiId, roiIdList: globalRoiIdList, isGlobalRoi: true }));
    }, [dispatch, globalRoiId, globalRoiIdList]);

    const handleIncludeAll = useCallback(() => {
        dispatch(roiCustomizationIncludedInModelUpdated({ roiId: globalRoiId, isIncluded: true, isGlobalRoi: true }));
    }, [dispatch, globalRoiId]);

    const handleExcludeAll = useCallback(() => {
        dispatch(roiCustomizationIncludedInModelUpdated({ roiId: globalRoiId, isIncluded: false, isGlobalRoi: true }));
    }, [dispatch, globalRoiId]);

    // track expanded cells by global roi ID & expanded column
    const [expandedCells, setExpandedCells] = useState<Record<string, RoiTableColumnKey>>({});

    const setCellExpanded = useCallback((globalRoiId: string, columnKey: RoiTableColumnKey, isExpanding: boolean) => {
        const newExpandedCells = { ...expandedCells };
        if (isExpanding) {
            newExpandedCells[globalRoiId] = columnKey;
        } else {
            delete newExpandedCells[globalRoiId];
        }
        setExpandedCells(newExpandedCells);
    }, [expandedCells]);
    const compoundExpandParams = useCallback((
        globalRoiId: string,
        columnKey: RoiTableColumnKey,
        rowIndex: number,
        columnIndex: number
    ): TdProps['compoundExpand'] => ({
        isExpanded: expandedCells[globalRoiId] === columnKey,
        onToggle: () => setCellExpanded(globalRoiId, columnKey, expandedCells[globalRoiId] !== columnKey),
        expandId: 'composable-compound-expandable-example',
        rowIndex,
        columnIndex
    }), [expandedCells, setCellExpanded]);

    if (globalRoi === undefined) {
        return null;
    }

    const expandedCellKey = expandedCells[globalRoiId];
    const isRowExpanded = !!expandedCellKey;

    return (
        <>
            <Tbody key={globalRoiId} isExpanded={isRowExpanded}>

                <RoiItem
                    roiId={globalRoiId}
                    isGlobalRoi={true}
                    hideIncludedOption
                    extraColumnsAfter={[
                        <Td key={columnIds.includedModels}
                            dataLabel={columnIds.includedModels}
                            compoundExpand={compoundExpandParams(globalRoiId, 'includedModels', rowIndex, 4)}
                            className={columnCssNames.includedModels}
                        >
                            {t('customizeRoi.global.covered.short', { count: globalRoi.coveredRois.length })}
                        </Td>,
                        <Td key={columnIds.excludedModels}
                            dataLabel={columnIds.excludedModels}
                            compoundExpand={compoundExpandParams(globalRoiId, 'excludedModels', rowIndex, 5)}
                            className={columnCssNames.excludedModels}
                        >
                            {t('customizeRoi.global.excluded.short', { count: globalRoi.excludedRois.length })}
                        </Td>,
                    ]}
                    rowActions={[
                        {
                            title: t('customizeRoi.showFullRoiDetails'),
                            onClick: handleShowFullRoiDetails,
                        },
                        {
                            title: globalRoi.isBuiltInRoi ? t('customizeRoi.revertNameToDefault') : t('customizeRoi.changeNameToMatchOperation'),
                            onClick: handleRevertName,
                            disabled: globalRoi.name === globalRoi.operation,
                        },
                    ]}
                />

                {isRowExpanded && expandedCellKey && (
                    <Tr isExpanded={isRowExpanded} className="global-roi-expanded-row">
                        <Td dataLabel={columnIds[expandedCellKey]} colSpan={11}>
                            <ExpandableRowContent>
                                {expandedCellKey === 'includedModels' && (
                                    <div>
                                        <div className="covered-models-instruction">
                                            This structure customization applies to following models.
                                            Adjusting this entry will adjust the matching structure customizations in all of the models below.
                                            You can choose to include or exclude this structure from each model as appropriate.
                                            If you need to individualize the customization of this structure for a specific model, do it through the invidual models selection.
                                        </div>

                                        <div className="covered-roi-operation-buttons">
                                            <Button size="sm" variant="tertiary" onClick={handleExcludeAll}>{t('customizeRoi.global.excludeAll')}</Button>
                                            <Button size="sm" variant="secondary" onClick={handleIncludeAll}>{t('customizeRoi.global.includeAll')}</Button>
                                        </div>

                                        {globalRoi.coveredRois.length === 0 ? (
                                            <EmptyState variant={EmptyStateVariant.xs}>
                                                <EmptyStateBody>This customization isn't covered in any models.</EmptyStateBody>
                                            </EmptyState>
                                        ) : (
                                            <Table variant='compact'>
                                                <Thead aria-label='global roi table'>
                                                    <Tr>
                                                        <Td>{t(columnLangKeys[columnIds.customization])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.targetFile])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.isIncluded])}</Td>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {globalRoi.coveredRois.map((rId: string) => {
                                                        return (
                                                            <CoveredRoi coveredRoiId={rId} key={rId} />
                                                        );
                                                    })}
                                                </Tbody>
                                            </Table>
                                        )}

                                    </div>
                                )}

                                {expandedCellKey === 'excludedModels' && (
                                    <div>
                                        <div className="covered-models-instruction">
                                            The following customizations of this same structure in different models differ from this global customization.
                                            You modify them here or in their model pages, and change them to match the global customization if necessary.
                                            Adjusting any of these differing entries individually will not change the global customization entry.
                                        </div>

                                        {globalRoi.excludedRois.length === 0 ? (
                                            <EmptyState variant={EmptyStateVariant.xs}>
                                                <EmptyStateBody>No different customizations found.</EmptyStateBody>
                                            </EmptyState>
                                        ) : (
                                            <Table variant='compact'>
                                                <Thead aria-label='global roi table'>
                                                    <Tr>
                                                        <Td></Td>
                                                        <Td>{t(columnLangKeys[columnIds.customization])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.targetFile])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.name])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.isIncluded])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.color])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.operation])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.interpretedType])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.fmaCodeValue])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.fmaCodeMeaning])}</Td>
                                                        <Td>{t(columnLangKeys[columnIds.fmaCodeSchemeDesignator])}</Td>
                                                        <Td></Td>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {globalRoi.excludedRois.map((rId: string) => {
                                                        return (
                                                            <ExcludedRoi excludedRoiId={rId} key={rId} nonMatchingGlobalRoiId={globalRoiId} excludedRoisList={globalRoi.excludedRois} />
                                                        );
                                                    })}
                                                </Tbody>
                                            </Table>
                                        )}
                                    </div>
                                )}

                            </ExpandableRowContent>
                        </Td>
                    </Tr>
                )}
            </Tbody>

        </>
    );
}

export default GlobalRoiCustomizationForm;
