import { useCallback, useEffect, useState } from 'react';
import {
	DataListItem,
	DataListItemRow,
	DataListItemCells,
	DataListCell,
	DataListAction,
	Flex,
	FlexItem,
	TextInput,
	Divider,
	Stack,
	StackItem,
	Alert
} from '@patternfly/react-core';
import {
	Dropdown,
	DropdownPosition,
	KebabToggle,
	DropdownItem,
	DropdownToggle
} from '@patternfly/react-core/deprecated';
import { useDispatch, useSelector } from 'react-redux';
import { CogIcon } from '@patternfly/react-icons';


import '../customization-page.css';
import { useScroll } from '../../../hooks/useScroll';
import InputLabel from '../../../components/input-label';
import { DEFAULT_FILENAME } from '../../../store/contouring/contouring-types';
import { useTranslation } from 'react-i18next';
import { ensureFilenameHasDcmExtension } from '../file-operations';
import { StoreState } from '../../../store/store';
import { contouringSelectors, metadataItemRemoved, metadataItemUpdated, scrollToViewFromModelCustomizationMetadataRemoved } from '../../../store/contouring/contouringSlice';
import { CustomizationObjectType, METADATA_FILENAME_ATTRIBUTE } from '../../../store/global-types/customization-types';

interface ModelMetadataItemFormProps {
    metadataId: string,
}

const ModelMetadataItemForm = (props: ModelMetadataItemFormProps) => {
    const { metadataId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isActionListOpen, setActionListOpenState] = useState(false);

    const metadataItem = useSelector((state: StoreState) => contouringSelectors.selectOutputMetadataById(state, metadataId));
    const validationError = useSelector((state: StoreState) => contouringSelectors.selectCustomizationValidationError(state, metadataId));
    const originalMetadataItem = useSelector((state: StoreState) => contouringSelectors.selectOriginalOutputMetadataById(state, metadataId));
    const [isPresetToggleOpen, setIsPresetToggleOpen] = useState(false);

    const handleUndo = useCallback(() => {
        dispatch(metadataItemUpdated({ metadataId: metadataId, attribute: originalMetadataItem!.attribute, value: originalMetadataItem!.value, isUndoOperation: true }));
    }, [dispatch, metadataId, originalMetadataItem]);

    const handleRemove = useCallback(() => {
        dispatch(metadataItemRemoved(metadataId));
    }, [dispatch, metadataId]);

    const handleAttributeChange = useCallback((attribute: string) => {
        dispatch(metadataItemUpdated({ metadataId: metadataId, attribute: attribute, value: undefined }));
    }, [dispatch, metadataId]);

    const handleValueChange = useCallback((_: unknown, value: string) => {
        dispatch(metadataItemUpdated({ metadataId: metadataId, attribute: undefined, value: value }));
    }, [dispatch, metadataId]);

    const handleBlur = useCallback((e?: never, attribute?: string) => {

        // optional 'attribute' argument allows us to call this function more easily
        // before we've applied updates to store
        const attrib = attribute || metadataItem?.attribute;

        // make sure a file name has an extension as we leave the matching input
        if (metadataItem && attrib === METADATA_FILENAME_ATTRIBUTE) {
            const updatedFilename = ensureFilenameHasDcmExtension(metadataItem.value || DEFAULT_FILENAME);
            if (updatedFilename !== metadataItem.value) {
                dispatch(metadataItemUpdated({ metadataId: metadataId, attribute: undefined, value: updatedFilename }));
            }
        }
    }, [dispatch, metadataId, metadataItem]);

    const handleToggleActionListOpen = useCallback(() => setActionListOpenState(!isActionListOpen), [isActionListOpen]);

    const [executeScroll, scrollRef] = useScroll<HTMLDivElement>(true);
    const [isHighlightedItem, setIsHighlightedItem] = useState(false);

    useEffect(() => {
        if (metadataItem && scrollRef && metadataItem.scrollToView) {
            setIsHighlightedItem(true);
            dispatch(scrollToViewFromModelCustomizationMetadataRemoved(metadataId));
            executeScroll();
        }
    }, [executeScroll, scrollRef, dispatch, metadataItem, metadataId]);

    const handleSelectPreset = useCallback((dicomAttributeName: string) => {
        handleAttributeChange(dicomAttributeName);
        setIsPresetToggleOpen(false);
        handleBlur(undefined, dicomAttributeName);
    }, [handleAttributeChange, handleBlur]);

    if (metadataItem === undefined) {
        return null;
    }

    const metadataAttributePresets = [
        <DropdownItem key="title" isPlainText>{t('customizeMetadata.presetLabel')}</DropdownItem>,
        <DropdownItem key="divider" isPlainText><Divider /></DropdownItem>,
        <DropdownItem key="filename" onClick={() => handleSelectPreset(METADATA_FILENAME_ATTRIBUTE)}>{METADATA_FILENAME_ATTRIBUTE}</DropdownItem>,
        <DropdownItem key="SeriesDescription" onClick={() => handleSelectPreset('SeriesDescription')}>SeriesDescription</DropdownItem>,
        <DropdownItem key="StructureSetLabel" onClick={() => handleSelectPreset('StructureSetLabel')}>StructureSetLabel</DropdownItem>,
    ];

    const prefix = `metadata-${metadataId}`;
    const attributeId = `${prefix}-attribute`;
    const valueId = `${prefix}-value`;

    const highlight = isHighlightedItem ? 'highlighted-item' : '';

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Metadata;

    return (
        <DataListItem aria-labelledby={`Metadata configuration for metadata item ${metadataId}`} key={metadataId}>
            <Stack>
                <StackItem>
                    <DataListItemRow className="roi-customization">

                        <DataListItemCells dataListCells={[
                            <DataListCell key="structure-customizations" className={`structure-customizations ${hasValidationError ? 'validation-error' : ''}`}>
                                <div ref={scrollRef} className="metadata-scroll-offset">
                                    {metadataItem.isModified && <div className="roi-item-is-modified" title="This metadata item has unsaved changes.">*</div>}
                                    <Flex className={`customization-row ${highlight}`}>
                                        <FlexItem className="metadata-attribute">
                                            <InputLabel label={t('customizeMetadata.attribute')} useRef>
                                                <TextInput
                                                    type="text"
                                                    id={attributeId}
                                                    onChange={evt => handleAttributeChange(evt.currentTarget.value)}
                                                    onBlur={handleBlur}
                                                    value={metadataItem.attribute}
                                                />
                                                <Dropdown
                                                    dropdownItems={metadataAttributePresets}
                                                    isOpen={isPresetToggleOpen}
                                                    title="Optional preset metadata attribute names"
                                                    toggle={<DropdownToggle toggleIndicator={null} onToggle={() => setIsPresetToggleOpen(!isPresetToggleOpen)}><CogIcon /></DropdownToggle>}
                                                />
                                            </InputLabel>
                                        </FlexItem>
                                        <FlexItem className="mv-flex-grow metadata-value">
                                            <InputLabel label={t('customizeMetadata.value')} useRef>
                                                <TextInput
                                                    type="text"
                                                    id={valueId}
                                                    onChange={handleValueChange}
                                                    onBlur={handleBlur}
                                                    value={metadataItem.value}
                                                />
                                            </InputLabel>
                                        </FlexItem>
                                    </Flex>
                                </div>
                            </DataListCell>
                        ]} />
                        <DataListAction id={`actions-for-${metadataId}`} aria-label={`Actions for metadata item ${metadataId}`} aria-labelledby="" isPlainButtonAction>
                            <Dropdown
                                isPlain
                                position={DropdownPosition.right}
                                isOpen={isActionListOpen}
                                onSelect={handleToggleActionListOpen}
                                toggle={<KebabToggle onToggle={handleToggleActionListOpen} />}
                                dropdownItems={[
                                    <DropdownItem
                                        key="undo-metadata-changes"
                                        component="button"
                                        isDisabled={originalMetadataItem === undefined || !metadataItem.isModified}
                                        onClick={handleUndo}>{t('customizeMetadata.undoChanges')}</DropdownItem>,
                                    <DropdownItem
                                        key="remove-metadata-item"
                                        component="button"
                                        onClick={handleRemove}>{t('customizeMetadata.removeItem')}</DropdownItem>,
                                ]}
                            />
                        </DataListAction>

                    </DataListItemRow>
                </StackItem>
                {hasValidationError && (
                    <StackItem className="validation-error-box">
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </StackItem>
                )}
            </Stack>
        </DataListItem>

    );
}

export default ModelMetadataItemForm;
