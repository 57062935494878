import {
	FormGroup
} from '@patternfly/react-core';
import {
	Dropdown,
	DropdownItem,
	DropdownToggle
} from '@patternfly/react-core/deprecated';
import React, { memo, useCallback } from 'react';

interface InterpretedTypeDropdownProps {
    onSelect: (interpretedType: string) => void,
    value: string,
}

const supportedInterpretedTypes = [
    'ORGAN',
    'EXTERNAL',
    'PTV',
    'CTV',
    'GTV',
    'CONTROL',
    'BOLUS',
    'AVOIDANCE',
    'MARKER',
    'CAVITY',
    'SUPPORT',
    'FIXATION',
    'REGISTRATION',
    'ISOCENTER',
    'CONTRAST_AGENT',
    'TREATED_VOLUME',
    'IRRAD_VOLUME',
    'BRACHY_CHANNEL',
    'BRACHY_ACCESSORY',
    'BRACHY_SRC_APP',
    'BRACHY_CHNL_SHLD',
    'DOSE_REGION',
    'DOSE_MEASUREMENT',
];

const dropdownItems = supportedInterpretedTypes.map(i => (<DropdownItem key={i}>{i}</DropdownItem>));

const InterpretedTypeDropdown = (props: InterpretedTypeDropdownProps) => {
    const { value, onSelect } = props;

    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggle = useCallback((_: unknown, isOpen: boolean) => {
        setIsOpen(isOpen);
    }, []);

    // const onFocus = useCallback(() => {
    //     const element = document.getElementById('toggle-basic');
    //     element.focus();
    // }, []);

    const handleSelect = useCallback((value: any) => {
        setIsOpen(false);
        // onFocus();
        onSelect(value.target.innerText)
    }, []);

    return (
        <FormGroup className="interpreted-type">
            <Dropdown
                dropdownItems={dropdownItems}
                toggle={(
                    <DropdownToggle onToggle={handleToggle}>
                        {value}
                    </DropdownToggle>
                )}
                isOpen={isOpen}
                onSelect={handleSelect}
            />
        </FormGroup>
    );
}

export default memo(InterpretedTypeDropdown);
