import React from 'react';
import { PageSection, PageSectionVariants, EmptyState, EmptyStateBody, EmptyStateHeader } from '@patternfly/react-core';

import { useSelector } from 'react-redux';

import '../customization-page.css';

import { useTranslation } from 'react-i18next';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';
import { doseSelectors } from '../../../store/dose/doseSlice';


interface ContourCustomizationPageProps {
    /** ID of the item we're currently focusing on (on what we last clicked on) */
    currentTreeViewFocus: string,
}


const ContourCustomizationPage = (props: ContourCustomizationPageProps) => {

    const { currentTreeViewFocus } = props;

    const { t } = useTranslation();

    // const baseIds = useSelector(doseSelectors.selectCustomizationBaseIds);
    // const outputIds = useSelector(doseSelectors.selectOutputIds);
    const customizationFetchError = useSelector((state: StoreState) => state.contouring.customizationFetchError);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);


    // // detect what type of view we're currently presenting
    // let currentViewType = CustomizationObjectType.None;
    // const isAnOutputCurrentlySelected = outputIds.includes(currentTreeViewFocus);
    // if (isAnOutputCurrentlySelected) {
    //     currentViewType = CustomizationObjectType.CustomizationOutput;
    // } else if (baseIds.includes(currentTreeViewFocus)) {
    //     currentViewType = CustomizationObjectType.CustomizationBase;
    // }

    return (
        <>
            {appConfig && appConfig.showErrorsInUi && customizationFetchError !== null ? (
                <PageSection variant={PageSectionVariants.light}>
                    <EmptyState>
                        <EmptyStateHeader titleText={<>{t('error.generic')}</>} headingLevel="h4" />
                        <EmptyStateBody>{t('error.customizationFetchFailed', { customizationFetchError: customizationFetchError })}</EmptyStateBody>
                    </EmptyState>
                </PageSection>
            ) : (
                <>
                Dose customization goes here
                    {/* <CustomizationToolbar
                        currentView={currentViewType}
                        customizationOutputId={currentTreeViewFocus}
                    />
                    <FullRoiDetailsModal />
                    <PageSection variant={PageSectionVariants.light}>
                        {isAnOutputCurrentlySelected ? (
                            <CustomizationForm
                                customizationOutputId={currentTreeViewFocus}
                            // modelValidation={activeValidation!} 
                            />
                        ) : (
                            <EmptyState>
                                <EmptyStateBody>{t('customizationPage.selectCustomization.message')}</EmptyStateBody>
                            </EmptyState>
                        )
                        }
                    </PageSection> */}
                </>
            )}
        </>
    );
}

export default ContourCustomizationPage;
