import { call, fork, put, select, take, takeEvery } from "typed-redux-saga";
import ConfigurationClient from "../../web-apis/configuration-client";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";
import { ContouringCustomizationEntities, ContouringCustomizationEntitiesForExport, ContouringCustomizationOutput, ContouringCustomizationOutputEntities, ContouringRoi, GlobalContouringRoi } from "./contouring-types";
import { isBackendValidationError, isInstanceOfError } from "../../util/errors";
import { allModelCustomizationsReset, contouringSelectors, customizationBaseDuplicated, customizationDuplicationItemsAdded, customizationOutputDuplicated, modelCustomizationExported, modelCustomizationExportFailed, modelCustomizationImported, modelCustomizationImportFailed, modelCustomizationObjectsSetAsModified, modelCustomizationOutputReplaced, modelCustomizationSaved, modelCustomizationsSet, outputDuplicationItemsAdded, resetModelCustomizationFinished, resetModelCustomizationStarted, resetSingleCustomizationOutputFinished, resetSingleCustomizationOutputStarted, saveModelCustomizationFinished, saveModelCustomizationStarted, singleModelCustomizationReset } from "./contouringSlice";
import { get } from "lodash-es";
import { Action, Update } from "@reduxjs/toolkit";
import { appConfigSelectors } from "../appConfig/appConfigSlice";
import { DeploymentMode } from "../appConfig/appDeploymentInfoTypes";
import { downloadFileToDisk } from "../../util/files";
import { convertContouringViewModelsToJson, createNewContouringCustomizationOutput, duplicateRoiCustomization } from "./contouring-helpers";
import { createNewModelCustomizationBase, duplicateModelCustomizationMetadataItem, generateNewId } from "../global-types/customization-helpers";
import { DuplicatedIdMap } from "./contouringReducerHelpers";
import { METADATA_FILENAME_ATTRIBUTE, OutputMetadataItem } from "../global-types/customization-types";
import { ensureFilenameHasDcmExtension } from "../../pages/customization/file-operations";
import { modelCustomizationPageFocusSet } from "../appStatus/appStatusSlice";

export function* fetchContouringCustomizationsSaga(appClient: MVisionAppClient | undefined) {
    const client = new ConfigurationClient();
    let contouringCustomizations: ContouringCustomizationEntities | null = null;
    let error = null;

    try {
        contouringCustomizations = appClient === undefined ? null : yield* call(async () => client.fetchContouringCustomizationsAsync(appClient));
        if (contouringCustomizations !== null) {
            const fetchModels = yield* call(async () => client.fetchAvailableModelsAsync(appClient));
            if (fetchModels !== undefined) {
                // if the available models collection is in use, mark which segmentation models are unavailable
                for (const model of contouringCustomizations.models) {
                    model.isAvailable = fetchModels.availableModels.includes(model.modelName);
                }
            }
        }
    }
    catch (ex) {
        console.error(ex);
        error = ex;
    }

    if (isInstanceOfError(error)) {
        yield* put(modelCustomizationsSet({ customizations: null, errorMessage: error.message || 'Unspecified error.' }));
        return false;
    } else {
        yield* put(modelCustomizationsSet({ customizations: contouringCustomizations }));
        return true;
    }
}

function* saveContouringCustomizationSaga(action: Action) {
    if (modelCustomizationSaved.match(action)) {
        const configurationTarget = action.payload;
        const client = new ConfigurationClient();
        let error = null;
        let errorMessage = null;
        let success = false;

        try {
            // collect model customization -- this is essentially a cache database dump (but global rois are not needed)
            const modelCustomization: ContouringCustomizationEntitiesForExport = yield* collectContouringCustomizationForExport();

            // sanity-check that everything is valid in case invalid data slips through the UI debouncing
            if (modelCustomization.aeTitleRules.some(r => !r.isValid)
                || modelCustomization.dicomRules.some(r => !r.isValid)
                || modelCustomization.dicomAttributeRules.some(r => !r.isValid)) {
                throw new Error(`Model configuration has invalid entries. Please fix them before saving.`);
            }

            yield* put(saveModelCustomizationStarted());
            success = yield* call(async () => client.saveContouringCustomizationAsync(configurationTarget, modelCustomization));
        }
        catch (ex) {
            console.error(ex);
            error = ex;
        }

        if (error !== null) {
            console.error('Was not able to save model customization');
            errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            console.error(errorMessage);
        } else if (!success) {
            errorMessage = 'Unable to save model customization. There might be a problem with the server.';
        }

        yield* put(saveModelCustomizationFinished({
            saveWasSuccessful: success,
            errorMessage: errorMessage || undefined,
            error: isBackendValidationError(error) ? error : undefined
        }));
    }
}

function* duplicateContourCustomizationSaga(action: Action) {
    if (customizationBaseDuplicated.match(action)) {
        const { customizationBaseId, newCustomizationName } = action.payload;

        const sourceCustomizationBase = yield* select(contouringSelectors.selectCustomizationBaseById, customizationBaseId);
        if (!sourceCustomizationBase) { throw new Error(`Could not retrieve customization base with id ${customizationBaseId}`); }
        const parentSegmentationModel = yield* select(contouringSelectors.selectModelById, sourceCustomizationBase.modelId);
        if (!parentSegmentationModel) { throw new Error(`Could not retrieve parent segmentation model with id ${sourceCustomizationBase.modelId}`); }

        const newCustomizationBaseId = generateNewId();

        // collect and map all ids we duplicate here so we can also duplicate related form errors to new targets
        const duplicatedIds: DuplicatedIdMap[] = [{ sourceId: customizationBaseId, targetId: newCustomizationBaseId }];

        // duplicate customization outputs (note: triggers are NOT duplicated)
        const newOutputs: ContouringCustomizationOutput[] = [];
        let newRois: ContouringRoi[] = [];
        let newMetadata: OutputMetadataItem[] = [];
        let globalRoiChanges: Update<GlobalContouringRoi, string>[] = [];
        for (const outputId of sourceCustomizationBase.outputs) {
            const sourceCustomizationOutput = yield* select(contouringSelectors.selectOutputById, outputId);
            if (!sourceCustomizationOutput) { throw new Error(`Could not retrieve customization output with id ${outputId}`); }
            const duplicationEntities = yield* duplicateCustomizationOutputFromStore(sourceCustomizationOutput, undefined, newCustomizationBaseId);
            newOutputs.push(duplicationEntities.duplicatedOutput);
            newRois = newRois.concat(duplicationEntities.newRois);
            newMetadata = newMetadata.concat(duplicationEntities.newMetadata);
            globalRoiChanges = globalRoiChanges.concat(duplicationEntities.globalRoiChanges);

            duplicatedIds.push(...duplicationEntities.duplicatedIds);
        }

        // duplicate customization base
        const duplicatedCustomizationBase = createNewModelCustomizationBase(
            newCustomizationName,
            sourceCustomizationBase.description,
            newOutputs.map(o => o.id),
            [],
            [],
            sourceCustomizationBase.modelId,
            newCustomizationBaseId,
            true
        );

        // store everything
        yield* put(customizationDuplicationItemsAdded({
            parentModelId: parentSegmentationModel.id,
            customization: duplicatedCustomizationBase,
            outputs: newOutputs,
            metadata: newMetadata,
            rois: newRois,
            globalRoiChanges: globalRoiChanges,
            duplicatedIds: duplicatedIds,
        }));

        // focus on the new duplicate
        if (newOutputs.length > 0) {
            yield* put(modelCustomizationPageFocusSet(newOutputs[0].id));
        }
    }
}

function* duplicateContourOutputSaga(action: Action) {
    if (customizationOutputDuplicated.match(action)) {
        const { customizationOutputId, newFilename } = action.payload;

        const sourceCustomizationOutput = yield* select(contouringSelectors.selectOutputById, customizationOutputId);
        if (!sourceCustomizationOutput) { throw new Error(`Could not retrieve customization output with id ${customizationOutputId}`); }
        const sourceCustomizationBase = yield* select(contouringSelectors.selectCustomizationBaseById, sourceCustomizationOutput.modelCustomizationBaseId);
        if (!sourceCustomizationBase) { throw new Error(`Could not retrieve customization base with id ${sourceCustomizationOutput.modelCustomizationBaseId}`); }

        const duplicationEntities = yield* duplicateCustomizationOutputFromStore(sourceCustomizationOutput, newFilename);

        // collect and map all ids we duplicate here so we can also duplicate related form errors to new targets
        const duplicatedIds: DuplicatedIdMap[] = [...duplicationEntities.duplicatedIds];

        // store everything
        yield* put(outputDuplicationItemsAdded({
            parentCustomizationId: sourceCustomizationBase.id,
            output: duplicationEntities.duplicatedOutput,
            metadata: duplicationEntities.newMetadata,
            rois: duplicationEntities.newRois,
            globalRoiChanges: duplicationEntities.globalRoiChanges,
            duplicatedIds
        }));

        // focus on the new duplicate
        yield* put(modelCustomizationPageFocusSet(duplicationEntities.duplicatedOutput.id));
    }
}

function* resetAllContouringCustomizationsSaga(action: Action) {
    if (allModelCustomizationsReset.match(action)) {
        const configurationTarget = action.payload;
        const client = new ConfigurationClient();
        let error = null;
        let errorMessage = null;
        let success = false;

        try {
            yield* put(resetModelCustomizationStarted());
            success = yield* call(async () => client.deleteContouringCustomizationAsync(configurationTarget));
            if (success) {
                // immediately fetch updated (reset) customizations
                success = yield* call(fetchContouringCustomizationsSaga, configurationTarget);
            }
        }
        catch (ex) {
            console.error(ex);
            error = ex;
        }

        if (error !== null) {
            console.error('Was not able to reset model customizations');
            errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            console.error(errorMessage);
        } else if (!success) {
            errorMessage = 'Unable to reset model customizations. There might be a problem with the server.';
        }

        // TODO: show results of error handling in UI
        // yield* put(resetModelCustomizationFinished(success, errorMessage || undefined, isInstanceOfError(error) ? error : undefined));

        yield* put(resetModelCustomizationFinished());
    }
}

function* resetSingleContouringCustomizationSaga(action: Action) {
    if (singleModelCustomizationReset.match(action)) {

        const customizationOutputId = action.payload;

        const output = yield* select(contouringSelectors.selectOutputById, customizationOutputId);
        const modelName = yield* select(contouringSelectors.selectModelNameForCustomizationOutput, customizationOutputId);

        if (!output || !modelName) {
            throw new Error('No model name specified, cannot fetch default configuration for model');
        }

        const client = new ConfigurationClient();
        let error = null;
        let errorMessage = null;
        let modelCustomizationEntities: ContouringCustomizationOutputEntities | null = null;


        try {
            yield* put(resetSingleCustomizationOutputStarted());

            // get default config for this model
            modelCustomizationEntities = yield* call(async () => client.fetchDefaultConfigurationForContouringOutput(modelName));

            // replace existing output and its items with received default items
            yield* put(modelCustomizationOutputReplaced({ customizationOutputId, newOutputEntities: modelCustomizationEntities }));
        }
        catch (ex) {
            console.error(ex);
            error = ex;
        }

        if (error !== null) {
            console.error(`Was not able to reset single customization output (${customizationOutputId})`);
            errorMessage = `An error occurred when trying to reset target file: ${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            console.error(errorMessage);
        }

        const success = error === null;
        yield* put(resetSingleCustomizationOutputFinished({
            resetWasSuccessful: success,
            errorMessage: errorMessage || undefined
        }));
    }
}

function* exportContouringCustomization(appClient: MVisionAppClient | undefined) {
    try {
        // collect model customization -- this is essentially a cache database dump (but global rois are not needed)
        const modelCustomization: ContouringCustomizationEntitiesForExport = yield* collectContouringCustomizationForExport();
        const jsonObject = yield* call(async () => convertContouringViewModelsToJson(modelCustomization, true));

        // Default filename if appClient?.userName is undefined or empty
        const defaultFilename = "mvision-model-customizations.json";
        const localModeFilename = "local.json";
        const appDeploymentInfo = yield* select(appConfigSelectors.selectAppDeploymentInfo);
        const isLocalMode = appDeploymentInfo && (appDeploymentInfo.deploymentMode === DeploymentMode.Cockpit || appDeploymentInfo.deploymentMode === DeploymentMode.Local);
        const filename = isLocalMode ? localModeFilename : (appClient?.userName ? `${appClient.userName}.json` : defaultFilename);

        downloadFileToDisk(JSON.stringify(jsonObject, null, 2), filename, 'application/json');
        yield* put(modelCustomizationExportFailed(null)); // resets any failure error message
    } catch (error) {
        console.log(`An error occurred in exportModelCustomization:`);
        console.error(error);

        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'JSON export failed.')}`;
        yield* put(modelCustomizationExportFailed(errorMessage));
    }
}

function* importContouringCustomization(modelCustomization: ContouringCustomizationEntities) {
    try {
        yield* put(modelCustomizationsSet({ customizations: modelCustomization }));
        yield* put(modelCustomizationObjectsSetAsModified(modelCustomization)); // set the entire ModelCustomizationEntities object as modified
        yield* put(modelCustomizationImportFailed(null));  // resets any failure error message
    } catch (error) {
        const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'JSON import failed.')}`;
        yield* put(modelCustomizationImportFailed(errorMessage));
    }
}




/// helper functions for contouring config sagas

function* collectContouringCustomizationForExport() {
    const modelCustomization: ContouringCustomizationEntitiesForExport = {
        models: yield* select(contouringSelectors.selectModels),
        customizationBases: yield* select(contouringSelectors.selectCustomizationBases),
        contouringOutputs: yield* select(contouringSelectors.selectOutputs),
        contouringRois: yield* select(contouringSelectors.selectRois),
        outputMetadata: yield* select(contouringSelectors.selectOutputMetadata),
        aeTitleRules: yield* select(contouringSelectors.selectAeTitleRules),
        dicomRules: yield* select(contouringSelectors.selectDicomRules),
        dicomAttributeRules: yield* select(contouringSelectors.selectDicomAttributeRules),
    };

    return modelCustomization;
}

type DuplicatedOutputEntities = {
    duplicatedOutput: ContouringCustomizationOutput;
    newRois: ContouringRoi[];
    newMetadata: OutputMetadataItem[];
    globalRoiChanges: Update<GlobalContouringRoi, string>[];
    duplicatedIds: DuplicatedIdMap[];
};

/**
 * Duplicates a customization output from store.
 * 
 * This should only be called from within contouringConfig reducers (or from unit tests).
 * 
 */
function* duplicateCustomizationOutputFromStore(
    sourceCustomizationOutput: ContouringCustomizationOutput,
    newFilename?: string,
    newCustomizationBaseId?: string) {

    const newRois: ContouringRoi[] = [];
    const newMetadata: OutputMetadataItem[] = [];
    const globalRoiChanges: Update<GlobalContouringRoi, string>[] = [];
    const newCustomizationOutputId = generateNewId();
    const duplicatedIds: DuplicatedIdMap[] = [{ sourceId: sourceCustomizationOutput.id, targetId: newCustomizationOutputId }];

    let duplicateFilename: string = '';

    // duplicate metadata
    for (const metadataId of sourceCustomizationOutput.metadata) {
        const metadata = yield* select(contouringSelectors.selectOutputMetadataById, metadataId);
        if (!metadata) { throw new Error(`Could not find customization metadata with id ${metadataId}`) }
        const duplicatedMetadata = duplicateModelCustomizationMetadataItem(metadata, newCustomizationOutputId);

        // handle filename metadata
        if (duplicatedMetadata.attribute === METADATA_FILENAME_ATTRIBUTE && newFilename !== undefined) {
            duplicatedMetadata.value = ensureFilenameHasDcmExtension(newFilename);
        }

        if (duplicatedMetadata.attribute === METADATA_FILENAME_ATTRIBUTE) {
            duplicateFilename = duplicatedMetadata.value;
        }

        newMetadata.push(duplicatedMetadata);
        duplicatedIds.push({ sourceId: metadataId, targetId: duplicatedMetadata.id });
    }

    // duplicate rois
    for (const roiId of sourceCustomizationOutput.rois) {
        const roi = yield* select(contouringSelectors.selectRoiById, roiId);
        if (!roi) { throw new Error(`Could not find roi with id ${roiId}`); }
        const duplicatedRoi = duplicateRoiCustomization(roi, newCustomizationOutputId);
        newRois.push(duplicatedRoi);
        duplicatedIds.push({ sourceId: roiId, targetId: duplicatedRoi.id });
    }

    // create new output
    const roiIds = newRois.map(r => r.id);
    const metadataIds = newMetadata.map(m => m.id);
    const duplicatedOutput = createNewContouringCustomizationOutput(metadataIds, roiIds, newCustomizationBaseId || sourceCustomizationOutput.modelCustomizationBaseId, duplicateFilename, newCustomizationOutputId, true);

    // update global rois -- don't recalculate globals
    for (const newRoi of newRois) {
        const globalRoiId = newRoi.globalRoiId;
        if (globalRoiId) {
            // if original roi matched a global roi, then the duplicate should also
            const globalRoi = yield* select(contouringSelectors.selectGlobalRoiById, globalRoiId);
            if (!globalRoi) { throw new Error(`Could not find global roi with id ${globalRoiId}`); }
            globalRoiChanges.push({ id: globalRoiId, changes: { coveredRois: globalRoi.coveredRois.concat(newRoi.id) } });
        } else {
            // if the original roi did not match a global roi, then find the matching entry and put the duplicate also into excluded rois
            const globalRois = yield* select(contouringSelectors.selectGlobalRois);
            const globalRoi = globalRois.find(g => g.operation === newRoi.operation);
            if (globalRoi) {
                globalRoiChanges.push({ id: globalRoi.id, changes: { excludedRois: globalRoi.excludedRois.concat(newRoi.id) } });
            }
        }
    }

    const result: DuplicatedOutputEntities = {
        duplicatedOutput,
        newMetadata,
        newRois,
        globalRoiChanges,
        duplicatedIds,
    };

    return result;
};




/// watches for contouring config sagas

function* watchSaveContouringCustomizationSaga() {
    // take all contouring customization save requests as they come -- do limiting simultaenous saves by temporarily 
    // disabling relevant ui elements instead of doing it with sagas here
    yield* takeEvery(modelCustomizationSaved, saveContouringCustomizationSaga);
}

export function* watchContourCustomizationDuplicatedSaga() {
    yield* takeEvery(customizationBaseDuplicated, duplicateContourCustomizationSaga);
}

export function* watchContourOutputDuplicatedSaga() {
    yield* takeEvery(customizationOutputDuplicated, duplicateContourOutputSaga);
}

function* watchResetAllContouringCustomizationsSaga() {
    yield* takeEvery(allModelCustomizationsReset, resetAllContouringCustomizationsSaga);
}

function* watchResetSingleContouringCustomizationSaga() {
    yield* takeEvery(singleModelCustomizationReset, resetSingleContouringCustomizationSaga);
}

function* watchExportContouringCustomizationSaga() {
    while (true) {
        const action = yield* take(modelCustomizationExported);
        const errorMessage = 'Export of model customization has failed.';
        if (!action.payload) {
            yield* put(modelCustomizationExportFailed(errorMessage));
            continue;
        }
        yield* fork(exportContouringCustomization, action.payload);
    }
}

function* watchImportContouringCustomizationSaga() {
    while (true) {
        const action = yield* take(modelCustomizationImported);
        const errorMessage = 'No JSON data provided for import.';

        if (!action.payload) {
            yield* put(modelCustomizationImportFailed(errorMessage));
            continue;
        }

        yield* fork(importContouringCustomization, action.payload);
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForContouringConfigSagas() {
    return [
        watchSaveContouringCustomizationSaga(),
        watchContourCustomizationDuplicatedSaga(),
        watchContourOutputDuplicatedSaga(),
        watchResetAllContouringCustomizationsSaga(),
        watchResetSingleContouringCustomizationSaga(),
        watchExportContouringCustomizationSaga(),
        watchImportContouringCustomizationSaga(),
    ];
}
