import React, { useCallback, useState } from 'react';
import {
	Toolbar,
	ToolbarContent,
	ToolbarItem,
	Button,
	Modal,
	ModalVariant,
	Alert,
	TextContent,
	Text
} from '@patternfly/react-core';
import {
	Dropdown,
	DropdownItem,
	DropdownPosition,
	DropdownToggle
} from '@patternfly/react-core/deprecated';
import { useDispatch, useSelector } from 'react-redux';
import { SaveIcon, UndoIcon, PlusIcon, TrashIcon, PencilAltIcon, EllipsisVIcon, HistoryIcon } from '@patternfly/react-icons'

import '../customization-page.css';
import { CustomizationObjectType, DEFAULT_MODEL_CUSTOMIZATION_NAME } from '../../../store/global-types/customization-types';
import { generateNewId } from '../../../store/global-types/customization-helpers';
import CustomizationNameField, { defaultDuplicatedModelNameValidation, validateDuplicatedModelName } from './CustomizationNameField';
import RenameCustomizationModal from './RenameCustomizationModal';
import ConfirmationModal from '../../../components/confirmation-modal';
import OutputFilenameField from '../OutputFilenameField';
import { useTranslation } from 'react-i18next';
import { hasUserName } from '../../../store/configurationTarget/mvision-client-list';
import { StoreState } from '../../../store/store';
import { appConfigSelectors } from '../../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../../store/configurationTarget/configurationTargetSlice';
import { advancedRoiCustomizationAdded, allCustomizationChangesReverted, contouringSelectors, customizationBaseDuplicated, customizationBaseRemoved, customizationOutputDuplicated, customizationOutputRemoved, metadataItemAdded, modelCustomizationSaved, singleModelCustomizationReset } from '../../../store/contouring/contouringSlice';

interface CustomizationToolbarProps {
    customizationOutputId?: string,
    currentView: CustomizationObjectType,
}

const CustomizationToolbar = (props: CustomizationToolbarProps) => {
    const { customizationOutputId, currentView } = props;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isUndoConfirmationModalOpen, setUndoConfirmationModalOpenState] = useState(false);
    const [isSaveConfirmationModalOpen, setSaveConfirmationModalOpenState] = useState(false);
    const [isAddNewCustomizationModalOpen, setAddNewCustomizationModalOpen] = useState(false);
    const [isAddNewFileModalOpen, setAddNewFileModalOpen] = useState(false);
    const [isRenameCustomizationModalOpen, setRenameCustomizationModalOpen] = useState(false);
    const [isDeleteCustomizationModalOpen, setDeleteCustomizationModalOpen] = useState(false);
    const [isDeleteOutputModalOpen, setDeleteOutputModalOpen] = useState(false);
    const [isResetOutputModalOpen, setResetOutputModalOpen] = useState(false);
    const [duplicatedCustomizationName, setDuplicatedCustomizationName] = useState('');
    const [duplicatedOutputFilename, setDuplicatedOutputFilename] = useState('');
    const [duplicatedCustomizationNameValidation, setDuplicatedCustomizationNameValidation] = useState(defaultDuplicatedModelNameValidation);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);
    const modelCustomizationSaveError = useSelector((state: StoreState) => state.contouring.modelCustomizationSaveError);
    const modelCustomizationDataError = useSelector((state: StoreState) => state.contouring.modelCustomizationDataError);
    const isSavingInProgress = useSelector((state: StoreState) => state.contouring.isModelCustomizationSavingInProgress);

    // needed for duplicated model name validation
    const allCustomizationNamesAndModelIds = useSelector(contouringSelectors.selectAllCustomizationNamesAndParentModelIds);

    const isAnyCustomizationModelModified = useSelector(contouringSelectors.selectIsAnyCustomizationModelModified);
    const noChanges = !isAnyCustomizationModelModified;

    const customizationOutput = useSelector((state: StoreState) => customizationOutputId ? contouringSelectors.selectOutputById(state, customizationOutputId) : undefined);
    const customizationBase = useSelector((state: StoreState) => customizationOutput ? contouringSelectors.selectCustomizationBaseById(state, customizationOutput.modelCustomizationBaseId) : undefined);
    const segmentationModel = useSelector((state: StoreState) => customizationBase ? contouringSelectors.selectModelById(state, customizationBase.modelId) : undefined);
    const isCustomizationOutputResetInProgress = useSelector((state: StoreState) => state.contouring.isCustomizationOutputResetInProgress);

    const handleToggleDropdownOpen = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    const handleOpenUndoDialog = useCallback(() => {
        setUndoConfirmationModalOpenState(true);
    }, []);

    const handleCloseUndoDialog = useCallback(() => {
        setUndoConfirmationModalOpenState(false);
    }, []);

    const handleOpenSaveDialog = useCallback(() => {
        setSaveConfirmationModalOpenState(true);
    }, []);

    const handleCloseSaveDialog = useCallback(() => {
        setSaveConfirmationModalOpenState(false);
    }, []);

    const handleOpenAddNewCustomizationModalDialog = useCallback(() => {
        setAddNewCustomizationModalOpen(true);
    }, []);

    const handleCloseAddNewCustomizationModalDialog = useCallback(() => {
        setAddNewCustomizationModalOpen(false);
    }, []);

    const handleOpenAddNewFileModalDialog = useCallback(() => {
        setAddNewFileModalOpen(true);
    }, []);

    const handleCloseAddNewFileModalDialog = useCallback(() => {
        setAddNewFileModalOpen(false);
    }, []);

    const handleOpenRenameCustomizationModalDialog = useCallback(() => {
        setRenameCustomizationModalOpen(true);
    }, []);

    const handleCloseRenameCustomizationModalDialog = useCallback(() => {
        setRenameCustomizationModalOpen(false);
    }, []);

    const handleOpenDeleteCustomizationModalDialog = useCallback(() => {
        setDeleteCustomizationModalOpen(true);
    }, []);

    const handleCloseDeleteCustomizationModalDialog = useCallback(() => {
        setDeleteCustomizationModalOpen(false);
    }, []);

    const handleOpenDeleteOutputModalDialog = useCallback(() => {
        setDeleteOutputModalOpen(true);
    }, []);

    const handleCloseDeleteOutputModalDialog = useCallback(() => {
        setDeleteOutputModalOpen(false);
    }, []);

    const handleOpenResetOutputModalDialog = useCallback(() => {
        setResetOutputModalOpen(true);
    }, []);

    const handleCloseResetOutputModalDialog = useCallback(() => {
        setResetOutputModalOpen(false);
    }, []);

    const handleUndo = useCallback(() => {
        if (!isSavingInProgress) {
            dispatch(allCustomizationChangesReverted());
            handleCloseUndoDialog();
        }
    }, [dispatch, handleCloseUndoDialog, isSavingInProgress]);

    const handleSave = useCallback(() => {
        if (!isSavingInProgress) {
            if (!currentTarget) {
                throw new Error('No configuration target set -- cannot save');
            }
            dispatch(modelCustomizationSaved(currentTarget));
            handleCloseSaveDialog();
        }
    }, [dispatch, currentTarget, isSavingInProgress, handleCloseSaveDialog]);

    const handleAddAdvancedStructure = useCallback(() => {
        if (customizationOutputId) {
            const newRoiId = generateNewId();
            dispatch(advancedRoiCustomizationAdded({ outputId: customizationOutputId, newRoiId: newRoiId }));
        }
    }, [dispatch, customizationOutputId]);

    const handleAddMetadataEntry = useCallback(() => {
        if (customizationOutputId) {
            dispatch(metadataItemAdded(customizationOutputId));
        }
    }, [dispatch, customizationOutputId]);

    const handleAddNewCustomization = useCallback(() => {
        if (customizationBase) {
            dispatch(customizationBaseDuplicated({ customizationBaseId: customizationBase.id, newCustomizationName: duplicatedCustomizationName }));
        }

        setAddNewCustomizationModalOpen(false);
        setDuplicatedCustomizationName('');
    }, [dispatch, customizationBase, duplicatedCustomizationName]);

    const handleAddNewFile = useCallback(() => {
        if (customizationOutputId) {
            dispatch(customizationOutputDuplicated({ customizationOutputId: customizationOutputId, newFilename: duplicatedOutputFilename }));
        }

        setAddNewFileModalOpen(false);
        setDuplicatedOutputFilename('');
    }, [dispatch, customizationOutputId, duplicatedOutputFilename]);

    const handleCustomizationDelete = useCallback(() => {
        handleCloseDeleteCustomizationModalDialog();
        if (customizationBase) {
            dispatch(customizationBaseRemoved(customizationBase.id));
        }
    }, [dispatch, handleCloseDeleteCustomizationModalDialog, customizationBase]);

    const handleOutputDelete = useCallback(() => {
        handleCloseDeleteOutputModalDialog();
        if (customizationOutput) {
            dispatch(customizationOutputRemoved(customizationOutput.id));
        }
    }, [dispatch, handleCloseDeleteOutputModalDialog, customizationOutput]);

    const handleOutputReset = useCallback(() => {
        handleCloseResetOutputModalDialog();
        if (customizationOutput) {
            dispatch(singleModelCustomizationReset(customizationOutput.id));
        }
    }, [dispatch, handleCloseResetOutputModalDialog, customizationOutput]);

    const handleDuplicatedCustomizationNameChange = useCallback((newCustomizationName: string) => {
        setDuplicatedCustomizationName(newCustomizationName);
        if (customizationBase && segmentationModel) {
            const validationResult = validateDuplicatedModelName(allCustomizationNamesAndModelIds, newCustomizationName, customizationBase.modelId);
            setDuplicatedCustomizationNameValidation(validationResult);
        } else {
            throw new Error('Could not retrieve matching customization base and/or segmentation model -- cannot validate new name');
        }
    }, [allCustomizationNamesAndModelIds, customizationBase, segmentationModel]);

    const handleDuplicatedOutputFilenameChange = useCallback((newFilename: string) => {
        setDuplicatedOutputFilename(newFilename);
    }, []);

    const isSaveModalActuallyOpen = isSaveConfirmationModalOpen || isSavingInProgress;

    const allowRenameCustomization = customizationBase !== undefined && customizationBase.customizationName !== DEFAULT_MODEL_CUSTOMIZATION_NAME;
    const allowRemoveCustomization = customizationBase !== undefined && customizationBase.customizationName !== DEFAULT_MODEL_CUSTOMIZATION_NAME;
    const allowRemoveOutput = customizationBase !== undefined && customizationBase.outputs.length > 1;

    return (
        <div className="model-customization-toolbar">
            {appConfig && appConfig.showErrorsInUi && modelCustomizationSaveError !== null && (
                <div className="toolbar-error">
                    <Alert variant="danger" isInline title="An error occurred when trying to save model customization">
                        <p>{modelCustomizationSaveError}</p>
                    </Alert>
                </div>
            )}
            {appConfig && appConfig.showErrorsInUi && modelCustomizationDataError !== null && (
                <div className="toolbar-error">
                    <Alert variant="danger" isInline title="An error occurred">
                        <p>{modelCustomizationDataError}</p>
                    </Alert>
                </div>
            )}
            <Toolbar>
                <ToolbarContent>
                    <ToolbarItem>
                        <Button
                            variant="primary"
                            icon={<SaveIcon />}
                            isDisabled={noChanges}
                            onClick={handleOpenSaveDialog}>{t('common.saveAllChanges')}</Button>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Button
                            variant="tertiary"
                            style={{ color: '#efefef' }}
                            icon={<UndoIcon />}
                            isDisabled={noChanges}
                            onClick={handleOpenUndoDialog}>{t('common.undoAllChanges')}</Button>
                    </ToolbarItem>

                    {(currentView === CustomizationObjectType.CustomizationOutput || currentView === CustomizationObjectType.CustomizationBase) && (
                        <>
                            <ToolbarItem variant="separator" />
                            <ToolbarItem>
                                <Button
                                    variant="secondary"
                                    icon={<PlusIcon />}
                                    title="Add a new customization for the selected model"
                                    onClick={handleOpenAddNewCustomizationModalDialog}>{t('customizationToolbar.addCustomization')}</Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                <Button
                                    variant="secondary"
                                    icon={<PlusIcon />}
                                    title="Add another target file for the selected customization"
                                    onClick={handleOpenAddNewFileModalDialog}>{t('customizationToolbar.addFile')}</Button>
                            </ToolbarItem>
                        </>)}

                    {currentView === CustomizationObjectType.CustomizationOutput && (
                        <>
                            <ToolbarItem variant="separator" />
                            <ToolbarItem>
                                <Button
                                    variant="secondary"
                                    icon={<PlusIcon />}
                                    onClick={handleAddAdvancedStructure}>{t('customizationToolbar.addAdvancedRoi')}</Button>
                            </ToolbarItem>
                            <ToolbarItem>
                                <Button
                                    variant="secondary"
                                    icon={<PlusIcon />}
                                    onClick={handleAddMetadataEntry}>{t('customizationToolbar.addMetadataEntry')}</Button>
                            </ToolbarItem>
                        </>)}

                    {(currentView === CustomizationObjectType.CustomizationBase || currentView === CustomizationObjectType.CustomizationOutput) && (
                        <>
                            <ToolbarItem variant="separator" />
                            <ToolbarItem align={{ default: 'alignRight' }}>
                                <Dropdown
                                    toggle={<DropdownToggle onToggle={handleToggleDropdownOpen} icon={<EllipsisVIcon />} toggleIndicator={null} toggleVariant='primary' />}
                                    isOpen={isDropdownOpen}
                                    position={DropdownPosition.right}
                                    dropdownItems={[
                                        <DropdownItem
                                            key="rename-customization"
                                            isDisabled={!allowRenameCustomization}
                                            title={!allowRenameCustomization ? `"${DEFAULT_MODEL_CUSTOMIZATION_NAME}" customizations cannot be renamed.` : undefined}
                                            icon={<PencilAltIcon />}
                                            onClick={handleOpenRenameCustomizationModalDialog}>
                                            {t('customizationToolbar.renameCustomization', { customizationName: customizationBase ? ` "${customizationBase.customizationName}"` : '' })}
                                        </DropdownItem>,
                                        <DropdownItem
                                            key="remove-customization"
                                            isDisabled={!allowRemoveCustomization || segmentationModel === undefined || segmentationModel.customizations.length <= 1}
                                            title={!allowRemoveCustomization ? `"${DEFAULT_MODEL_CUSTOMIZATION_NAME}" customizations cannot be removed.` : undefined}
                                            icon={<TrashIcon />}
                                            onClick={handleOpenDeleteCustomizationModalDialog}>
                                            {t('customizationToolbar.removeCustomization', { customizationName: customizationBase ? `"${customizationBase.customizationName}"` : '' })}
                                        </DropdownItem>,
                                        <DropdownItem
                                            key="remove-output"
                                            isDisabled={currentView !== CustomizationObjectType.CustomizationOutput || !allowRemoveOutput}
                                            title={allowRemoveOutput ? 'Customizations must have at least one target file -- the last file cannot be removed.' : undefined}
                                            icon={<TrashIcon />}
                                            onClick={handleOpenDeleteOutputModalDialog}>
                                            {t('customizationToolbar.removeTargetFile', { targetFileName: customizationOutput ? `"${customizationOutput.filename}"` : '' })}
                                        </DropdownItem>,
                                        <DropdownItem
                                            key="reset-output"
                                            isDisabled={currentView !== CustomizationObjectType.CustomizationOutput}
                                            title={t('customizationToolbar.resetTargetFile.title')}
                                            icon={<HistoryIcon />}
                                            onClick={handleOpenResetOutputModalDialog}>
                                            {t('customizationToolbar.resetTargetFile', { targetFileName: customizationOutput ? `"${customizationOutput.filename}"` : '' })}
                                        </DropdownItem>
                                    ]}
                                />
                            </ToolbarItem>
                        </>)}

                </ToolbarContent>
            </Toolbar>

            <Modal
                variant={ModalVariant.large}
                title={`Save current model selection configuration${hasUserName(currentTarget) ? ` for ${currentTarget!.userName}` : ''}?`}
                isOpen={isSaveModalActuallyOpen}
                onClose={handleCloseSaveDialog}
                actions={[
                    <Button key="confirmSave" variant="primary" isLoading={isSavingInProgress} onClick={handleSave}>{t('common.saveAll')}</Button>,
                    <Button key="cancel" disabled={isSavingInProgress} variant="tertiary" onClick={handleCloseSaveDialog}>{t('common.cancel')}</Button>
                ]}
            >
                Do you want to overwrite existing model customization{hasUserName(currentTarget) ? ` for ${currentTarget!.userName}` : ''} with your changes? Note
                that this will save <b>all</b> modifications to customization for <b>all structures and segmentation models</b>. Any previous customizations
                are lost. You can always revert back to default settings.
            </Modal>

            <Modal
                isOpen={isUndoConfirmationModalOpen}
                onClose={handleCloseUndoDialog}
                variant={ModalVariant.small}
                title="Confirm undo"
                actions={[
                    <Button key="confirmUndo" variant="danger" onClick={handleUndo}>{t('common.undoAllChanges')}</Button>,
                    <Button key="cancelUndo" variant="secondary" onClick={handleCloseUndoDialog}>{t('common.keepChanges')}</Button>
                ]}
            >
                Are you sure you wish to undo all changes to customization? Note that this will revert <b>all</b> modifications to customizations
                for <b>all structures and segmentation models</b>. All changes that have not been saved will be lost.
            </Modal>

            {customizationBase && segmentationModel && (
                <Modal
                    isOpen={isAddNewCustomizationModalOpen}
                    onClose={handleCloseAddNewCustomizationModalDialog}
                    variant={ModalVariant.medium}
                    title={t('customizationToolbar.addCustomization.title')}
                    className="duplicate-model-modal"
                    position="top"
                    positionOffset="15%"
                    actions={[
                        <Button key="confirmAdd" variant="primary" isDisabled={duplicatedCustomizationNameValidation.validated === 'error' || !duplicatedCustomizationName} onClick={handleAddNewCustomization}>{t('customizationToolbar.addNewCustomization')}</Button>,
                        <Button key="cancelAdd" variant="tertiary" onClick={handleCloseAddNewCustomizationModalDialog}>{t('common.cancel')}</Button>
                    ]}
                >
                    <TextContent className="mv-section">
                        <Text component="p">{t('customizationToolbar.addCustomization.paragraph1')}</Text>
                    </TextContent>

                    <div>
                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addCustomization.paragraph2')}</Text>
                        </TextContent>

                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addCustomization.paragraph3')}</Text>
                        </TextContent>

                        <div className="mv-section">
                            <CustomizationNameField
                                segmentationModelName={segmentationModel.modelName}
                                sourceCustomizationName={customizationBase.customizationName}
                                customizationNameValidation={duplicatedCustomizationNameValidation}
                                onCustomizationNameChange={handleDuplicatedCustomizationNameChange}
                                customizationName={duplicatedCustomizationName}
                            />
                        </div>
                    </div>

                </Modal>
            )}

            {customizationBase && segmentationModel && customizationOutput && (
                <Modal
                    isOpen={isAddNewFileModalOpen}
                    onClose={handleCloseAddNewFileModalDialog}
                    variant={ModalVariant.medium}
                    title={t('customizationToolbar.addOutput.title')}
                    className="duplicate-model-modal"
                    position="top"
                    positionOffset="15%"
                    actions={[
                        <Button key="confirmAdd" variant="primary" isDisabled={!duplicatedOutputFilename} onClick={handleAddNewFile}>{t('customizationToolbar.addNewTargetFile')}</Button>,
                        <Button key="cancelAdd" variant="tertiary" onClick={handleCloseAddNewFileModalDialog}>{t('common.cancel')}</Button>
                    ]}
                >
                    <TextContent className="mv-section">
                        <Text component="p">{t('customizationToolbar.addOutput.paragraph1')}</Text>
                    </TextContent>

                    <div>
                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addOutput.paragraph2')}</Text>
                        </TextContent>

                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addOutput.paragraph3')}</Text>
                        </TextContent>

                        <div className="mv-section">
                            <OutputFilenameField
                                segmentationModelName={segmentationModel.modelName}
                                sourceCustomizationName={customizationBase.customizationName}
                                sourceFilename={customizationOutput.filename}
                                duplicatedFilename={duplicatedOutputFilename}
                                onOutputFilenameChange={handleDuplicatedOutputFilenameChange}
                            />
                        </div>
                    </div>

                </Modal>
            )}

            {customizationBase && segmentationModel && (
                <>
                    <RenameCustomizationModal
                        isOpen={isRenameCustomizationModalOpen}
                        onClose={handleCloseRenameCustomizationModalDialog}
                        model={segmentationModel}
                        customization={customizationBase}
                    />

                    <ConfirmationModal
                        isOpen={isDeleteCustomizationModalOpen}
                        title="Confirm deletion of customization"
                        onConfirm={handleCustomizationDelete}
                        onClose={handleCloseDeleteCustomizationModalDialog}
                        confirmLabel="Delete"
                        variant={ModalVariant.small}
                        confirmVariant="danger"
                    >
                        Are you sure you wish to delete customization {customizationBase.customizationName} from {segmentationModel.modelName}?
                    </ConfirmationModal>
                </>
            )}

            {customizationOutput && customizationBase && segmentationModel && (
                <ConfirmationModal
                    isOpen={isDeleteOutputModalOpen}
                    title="Confirm deletion of target file"
                    onConfirm={handleOutputDelete}
                    onClose={handleCloseDeleteOutputModalDialog}
                    confirmLabel="Delete"
                    variant={ModalVariant.small}
                    confirmVariant="danger"
                >
                    Are you sure you wish to delete target file {customizationOutput.filename} from customization {customizationBase.customizationName} (from {segmentationModel.modelName})?
                </ConfirmationModal>
            )}

            {customizationOutput && customizationBase && segmentationModel && (
                <ConfirmationModal
                    isOpen={isResetOutputModalOpen || isCustomizationOutputResetInProgress}
                    title={t('customizationToolbar.resetTargetFile.confirm.title')}
                    onConfirm={handleOutputReset}
                    onClose={handleCloseResetOutputModalDialog}
                    confirmLabel={t('common.reset')}
                    variant={ModalVariant.small}
                    confirmVariant="danger"
                    isLoadingInProgress={isCustomizationOutputResetInProgress}
                >
                    {t('customizationToolbar.resetTargetFile.confirm.message',
                        { targetFileName: customizationOutput.filename, customizationName: customizationBase.customizationName, segmentationModelName: segmentationModel.label })}
                </ConfirmationModal>
            )}


        </div>
    );
}

export default CustomizationToolbar;
