import { call, put, select, spawn, take } from "typed-redux-saga";
import { LoginType } from "../../app-config";
import { backendTierAppAuths } from "../../web-apis/cloud-backend-auth";
import { isInstanceOfError } from "../../util/errors";
import { appConfigSelectors } from '../appConfig/appConfigSlice';
import { createRequiredAppAuthState } from "./auth";
import { appAuthLoginStarted, appAuthStateAdded, loginErrorSet, logInSucceeded, logOutRequested } from "./authSlice";
import { fetchUserAccessRightsSaga } from "../user/userSagas";
import { fetchConfigurationTargetListSaga } from "../configurationTarget/configurationTargetSagas";

export function* firstTimeCloudLoginSaga() {

    // return out of this function immediately if azure login is not warranted
    const appConfig = yield* select(appConfigSelectors.selectAppConfig);
    if (!appConfig) { throw new Error('App config not initialized -- cannot log in'); }
    if (appConfig.loginType !== LoginType.AzureLogin) {
        return;
    }

    const backend = yield* select(appConfigSelectors.selectBackend);
    if (backend === undefined) {
        throw new Error('No backend defined');
    }

    // create a new app auth state for tracking user's login process
    if (!backend.tier) { throw new Error('Backend tier must be specified when logging in in cloud environment.'); }
    const appAuth = backendTierAppAuths[backend.tier];
    const appAuthState = createRequiredAppAuthState(appAuth.appName);
    yield* put(appAuthStateAdded(appAuthState));

    // log in
    yield* put(appAuthLoginStarted(appAuthState.appAuthName));
    try {
        yield* call([appAuth, 'logIn']);

        yield* put(logInSucceeded(appAuthState.appAuthName));

        // do some extra stuff after login:

        // immediately fetch access rights, user settings & client list
        yield* spawn(fetchUserAccessRightsSaga);

        // yield* spawn(fetchUserSettingsSaga); // TODO: fetch user settings here once we have something to actually fetch and use!

        yield* spawn(fetchConfigurationTargetListSaga);

        // // first authentication should also have authenticated current user with rtviewer backend
        // const userDetails: { username: string, email: string, permissions: UserPermissions } = yield* call([rtViewerApiClient, 'getAuthenticatedUser']);
        // yield* put({ type: Store.setUserDetails, ...userDetails });
    } catch (err) {
        const loginError = isInstanceOfError(err) ? err : new Error('An error occurred during login.');
        yield* put(loginErrorSet(loginError));
    }
}

function* watchUserLogOutSaga() {
    while (true) {
        yield* take(logOutRequested);
        const firstLoggedInAppAuth = Object.values(backendTierAppAuths).find(a => a.isLoggedIn);
        if (firstLoggedInAppAuth) {
            yield* call(async () => firstLoggedInAppAuth.logOut());
        }
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForAuthSagas() {
    return [
        watchUserLogOutSaga(),
    ];
}
