import { TextContent, Text, Level, LevelItem, FormGroup, TextInput } from '@patternfly/react-core';
import { ArrowRightIcon } from '@patternfly/react-icons'

import '../customization-page.css';
import InputLabel from '../../../components/input-label';
import { FormValidationState } from '../../../components/form-validation-state';
import { useTranslation } from 'react-i18next';
import ValidationHelperText from '../../../components/validation-helper-text';
import { useCallback } from 'react';

interface CustomizationNameFieldProps {
    segmentationModelName: string,
    sourceCustomizationName: string,
    customizationNameValidation: FormValidationState,
    onCustomizationNameChange: (newCustomizationName: string) => void,
    customizationName: string,
}

export const defaultDuplicatedModelNameValidation: FormValidationState = { validated: 'default', helperTextInvalid: null };
export const emptyDuplicatedModelNameNameValidation: FormValidationState = { validated: 'error', helperTextInvalid: 'New customization must be given a name.' };
export const duplicateNameDuplicatedModelNameNameValidation: FormValidationState = { validated: 'error', helperTextInvalid: 'New customization must be given a unique name.' };

export const validateDuplicatedModelName = (allCustomizationNamesAndIds: { customizationName: string, modelId: string }[], requestedName: string, modelId?: string): FormValidationState => {
    if (!requestedName) {
        return emptyDuplicatedModelNameNameValidation;
    }

    if (allCustomizationNamesAndIds.some(cn => cn.customizationName === requestedName && (modelId === undefined || cn.modelId === modelId))) {
        return duplicateNameDuplicatedModelNameNameValidation;
    }

    return defaultDuplicatedModelNameValidation;
}

const CustomizationNameField = (props: CustomizationNameFieldProps) => {
    const { segmentationModelName, sourceCustomizationName, customizationNameValidation, onCustomizationNameChange, customizationName: duplicatedCustomizationName } = props;
    const { t } = useTranslation();

    const handleCustomizationNameChange = useCallback((_: unknown, newCustomizationName: string) => {
        onCustomizationNameChange(newCustomizationName);
    }, [onCustomizationNameChange]);

    return (
        <>
            <TextContent className="model-name">
                <Text component="h6">{t('customizationPage.customizationNameField.title')}</Text>
            </TextContent>

            <Level className="ae-title-row duplicated-model-name-fields">
                <LevelItem>
                    <FormGroup
                        label={t('customizationPage.customizationNameField.duplicatingFrom')}
                        fieldId="duplication-source-name"
                        title={undefined}
                    >
                        <div className="duplication-source">
                            <span className="duplication-model-name">{segmentationModelName}</span>
                            <span className="duplication-separator">.</span>
                            <span className="duplication-customization-name">{sourceCustomizationName}</span>
                        </div>
                    </FormGroup>
                </LevelItem>
                <LevelItem className="duplication-arrow">
                    <ArrowRightIcon />
                </LevelItem>
                <LevelItem className="ae-title-aetitle-column">
                    <FormGroup
                        label={t('customizationPage.customizationNameField.newCustomization')}
                        fieldId="duplicated-model-name"
                        title={undefined}
                    >
                        <InputLabel label={`${segmentationModelName}.`} useRef className="duplicate-model-name-field">
                            <TextInput
                                type="text"
                                id="duplicated-model-name"
                                onChange={handleCustomizationNameChange}
                                value={duplicatedCustomizationName}
                                validated={customizationNameValidation.validated}
                            />
                        </InputLabel>
                        {customizationNameValidation.validated !== 'default' && (
                            <ValidationHelperText
                                validated={customizationNameValidation.validated}
                                helperText={customizationNameValidation.helperTextInvalid}
                            />
                        )}
                    </FormGroup>
                </LevelItem>
            </Level>
        </>
    );
}

export default CustomizationNameField;
