import {
	Alert,
	Button,
	Card,
	CardBody,
	CardTitle,
	ExpandableSection,
	Modal,
	MenuToggle,
    DropdownList,
    DropdownItem,
    Dropdown,
    MenuToggleElement
} from '@patternfly/react-core';
import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { supportedLanguages } from "../../i18n";
import { backendTierAppAuths } from "../../web-apis/cloud-backend-auth";

import './UserSettings.css';
import { getUserName } from "../../store/configurationTarget/mvision-client-list";
import ConfigurationImportExport from "./ConfigurationImportExport";
import { StoreState } from "../../store/store";
import { logOutRequested } from "../../store/auth/authSlice";
import { appConfigSelectors } from "../../store/appConfig/appConfigSlice";
import { configurationTargetSelectors } from "../../store/configurationTarget/configurationTargetSlice";
import { allModelCustomizationsReset } from "../../store/contouring/contouringSlice";

const UserSettings = () => {

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const onLogOutClick = useCallback(() => {
        dispatch(logOutRequested());
    }, [dispatch]);

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);

    const [isLangDropdownOpen, setIsLangDropdownOpen] = React.useState(false);
    const [isResetBoxExpanded, setIsResetBoxExpanded] = React.useState(false);
    const [isResetConfirmationModalOpen, setIsResetConfirmationModalOpen] = React.useState(false);
    const [isResetCompletedTextVisible, setIsResetCompletedTextVisible] = React.useState(false);
    const [isResetting, setIsResetting] = React.useState(false);

    const deploymentInfo = useSelector(appConfigSelectors.selectAppDeploymentInfo);
    const isResetInProgress = useSelector((state: StoreState) => state.contouring.isModelCustomizationResetInProgress);

    const handleLangDropdownToggle = useCallback((isOpen: boolean) => {
        setIsLangDropdownOpen(isOpen);
    }, []);

    const onLangSelect = useCallback((nextLang: string) => {
        const langKey = supportedLanguages.find(l => l.label === nextLang)?.langKey;
        i18n.changeLanguage(langKey);
    }, [i18n]);

    const handleLangSelect = useCallback((value: any) => {
        setIsLangDropdownOpen(false);
        onLangSelect(value.target.innerText)
    }, [onLangSelect]);

    const handleExpandResetBoxClicked = useCallback((_: unknown, isOpen: boolean) => {
        setIsResetBoxExpanded(isOpen);
    }, []);

    const handleResetButtonClicked = useCallback(() => {
        setIsResetConfirmationModalOpen(true);
    }, []);

    const handleCloseResetModalClicked = useCallback(() => {
        setIsResetConfirmationModalOpen(false);
    }, []);

    const handleConfirmResetClicked = useCallback(() => {
        if (!isResetInProgress) {
            if (!currentTarget) {
                throw new Error('No configuration target selected, cannot reset');
            }
            dispatch(allModelCustomizationsReset(currentTarget));
        }
        setIsResetConfirmationModalOpen(false);
        setIsResetting(true);
        setIsResetCompletedTextVisible(false);
    }, [currentTarget, dispatch, isResetInProgress]);

    useEffect(() => {
        if (isResetting && !isResetInProgress) {
            // show reset completed text once it's done
            setIsResetCompletedTextVisible(true);
        }
    }, [isResetInProgress, isResetting]);

    const loggedInUsernames = Object.values(backendTierAppAuths)
        .filter(appAuth => appAuth.isLoggedIn)
        .map(appAuth => ({ appName: appAuth.appName, user: appAuth.loggedInUser }));

    // show supported languages, but filter out languages set as omitted from UI in config.json
    const langItems = supportedLanguages.filter(l => !deploymentInfo || !deploymentInfo.omitLocalizationsFromUi || !deploymentInfo.omitLocalizationsFromUi.includes(l.langKey))
        .map(l => (<DropdownItem key={l.langKey} value={l.langKey} size={2}>{l.label}</DropdownItem>))
    const currentLanguage = supportedLanguages.find(l => l.langKey === i18n.resolvedLanguage)?.label;

    return (
        <div className="user-settings">
            {loggedInUsernames.length > 0 &&
                (
                    <div className="setting-item">
                        <span className="label">{t('settings.loggedInAs')}</span>
                        <span className="value" title={loggedInUsernames[0].user?.email}>
                            {loggedInUsernames.length > 1 ?
                                loggedInUsernames.map(appNameAndUser => (<div key={appNameAndUser.appName} title={appNameAndUser.user?.email}>{appNameAndUser.user?.fullName} ({appNameAndUser.appName})</div>))
                                : loggedInUsernames[0].user?.fullName}
                        </span>
                        <span className="log-out-button"><Button variant="danger" onClick={onLogOutClick}>{t('common.logOut')}</Button></span>
                    </div>
                )
            }

            <div className="setting-item">
                <span className="label">{t('settings.language')}</span>
                <span className="value">
                    <Dropdown
                        toggle={(toggleRef: React.Ref<MenuToggleElement>) => (
                            <MenuToggle ref={toggleRef} onClick={_ => handleLangDropdownToggle(!isLangDropdownOpen)} isExpanded={isLangDropdownOpen}>
                                {currentLanguage}
                            </MenuToggle>)
                        }
                        isOpen={isLangDropdownOpen}
                        onSelect={handleLangSelect}>
                            <DropdownList>{langItems}</DropdownList>
                    </Dropdown>
                </span>
            </div>

            {currentTarget ? (
                <Card isFlat className="reset-to-factory-settings-box">
                    <CardTitle>
                        <Trans i18nKey="settings.configurationTarget.title">
                            Settings for configuration target: <span className="configuration-target-box-name">{{ configurationTargetName: getUserName(currentTarget) }}</span>
                        </Trans>
                    </CardTitle>
                    <CardBody>
                        <ExpandableSection toggleContent={(<b>{t('settings.resetConfiguration.title')}</b>)} onToggle={handleExpandResetBoxClicked} isExpanded={isResetBoxExpanded} displaySize="lg" isWidthLimited>
                            <div className="setting-item">
                                <span className="label">{t('settings.resetConfiguration.text')}</span>
                                <span className="value">
                                    <Button variant="danger" onClick={handleResetButtonClicked} isDisabled={isResetInProgress} isLoading={isResetInProgress}>{t('settings.resetConfiguration.confirm.button')}</Button>
                                </span>
                            </div>

                            {isResetCompletedTextVisible && (
                                <div className="configuration-reset-completed-box">
                                    <Alert variant="info" isInline title={t('settings.resetConfiguration.success')} />
                                </div>
                            )}

                            <div>
                                <Trans i18nKey="settings.resetConfiguration.warning">
                                    Resetting model configuration to factory settings will remove all current model customizations and model selections, including any unsaved changes, and return them back to default values. This operation <b>cannot be reverted.</b>
                                </Trans>
                            </div>
                        </ExpandableSection>
                        <br />
                        <ConfigurationImportExport />
                    </CardBody>
                </Card>
            ) : (
                <Card isFlat className="reset-to-factory-settings-box">
                    <CardTitle>{t('settings.configurationTarget.empty.title')}</CardTitle>
                    <CardBody>
                        <div>{t('settings.configurationTarget.empty.title')}</div>
                    </CardBody>
                </Card>
            )}

            <Modal
                isOpen={isResetConfirmationModalOpen}
                onClose={handleCloseResetModalClicked}
                variant={"medium"}
                title={t('settings.resetConfiguration.confirm.title')}
                actions={[
                    <Button key="confirm-reset" onClick={handleConfirmResetClicked} variant="danger">{t('settings.resetConfiguration')}</Button>,
                    <Button key="cancel-reset" onClick={handleCloseResetModalClicked} variant="tertiary">{t('common.cancel')}</Button>
                ]}
                className="reset-confirmation-modal">
                <div>
                    <Trans i18nKey="settings.resetConfiguration.confirm.caution1">
                        Reset configuration settings for configuration target <span className="reset-emphasis configuration-target-name">{{ configurationTargetName: currentTarget ? getUserName(currentTarget) : '' }}</span> back to factory defaults?
                    </Trans>
                </div>
                <div>
                    <Trans i18nKey="settings.resetConfiguration.confirm.caution2">
                        Resetting model configuration to factory settings will remove all current model customizations and model selections, including any unsaved changes, and return them back to default values. This operation <span className="reset-emphasis">cannot be reverted.</span>
                    </Trans>
                </div>
            </Modal >

        </div >
    );
}

export default UserSettings;
