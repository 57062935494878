import React, { useCallback } from 'react';
import {
	Modal,
	ModalVariant,
	Button,
	Grid,
	GridItem,
	FormGroup,
	TextInput,
	Form,
	Card,
	CardTitle,
	CardBody,
	Alert
} from '@patternfly/react-core';
import {
	Dropdown,
	DropdownToggle,
	DropdownToggleAction,
	DropdownItem
} from '@patternfly/react-core/deprecated';

import '../customization-page.css';
import { useDispatch, useSelector } from 'react-redux';
import RoiItem from './RoiItem';
import { Table, Tbody, Td, Thead, Tr } from '@patternfly/react-table';
import { columnIds, columnLangKeys } from './RoiTable';
import { get } from "lodash-es";
import { useTranslation } from 'react-i18next';
import PhysicalPropertiesDropdown from './PhysicalPropertiesDropdown';
import { CodingScheme, GlobalContouringRoi, PhysicalProperty, ContouringRoi, isContouringRoi } from '../../../store/contouring/contouring-types';
import ConfirmationModal from '../../../components/confirmation-modal';
import { extractSharedCodingSchemeAttributes } from '../../../store/contouring/contouring-helpers';
import { StoreState } from '../../../store/store';
import { contouringSelectors, roiCodingSchemeCopied, roiCodingSchemeCopiedToOutput, roiCodingSchemeUpdated, roiPhysicalPropertyUpdated } from '../../../store/contouring/contouringSlice';
import { fullRoiDetailsHidden, fullRoiDetailsShown } from '../../../store/appStatus/appStatusSlice';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';


const GRID_SPAN = 4;

const enum CodingSchemeCopyTarget {
    'AllRois' = 'all-rois',
    'WithinOutput' = 'within-output',
}

const FullRoiDetailsModal = () => {
    const [isCopyFmaIdsConfirmationModalOpen, setCopyFmaIdsConfirmationModalOpen] = React.useState(false);
    const [confirmationModalKey, setConfirmationModalKey] = React.useState(CodingSchemeCopyTarget.AllRois);
    const [isCopyFmaIdsSelectOpen, setCopyFmaIdsSelectOpen] = React.useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const fullRoiDetails = useSelector((state: StoreState) => state.appStatus.fullRoiDetails);
    const isGlobalRoi = fullRoiDetails !== undefined && fullRoiDetails.isGlobalRoi === true;
    const validationError = useSelector((state: StoreState) => fullRoiDetails ? contouringSelectors.selectCustomizationValidationError(state, fullRoiDetails.currentRoiId) : undefined);

    const roi = useSelector((state: StoreState): GlobalContouringRoi | ContouringRoi | undefined => {
        if (!fullRoiDetails) {
            return undefined;
        }
        return isGlobalRoi
            ? contouringSelectors.selectGlobalRoiById(state, fullRoiDetails.currentRoiId)
            : contouringSelectors.selectRoiById(state, fullRoiDetails.currentRoiId);
    });

    const handleClose = useCallback(() => {
        dispatch(fullRoiDetailsHidden());
    }, [dispatch]);

    const handlePreviousRoi = useCallback(() => {
        if (fullRoiDetails) {
            const { currentRoiId, roiIdList } = fullRoiDetails;
            const currentRoiListIndex = roiIdList.indexOf(currentRoiId);
            if (currentRoiListIndex > 0) {
                dispatch(fullRoiDetailsShown({ currentRoiId: roiIdList[currentRoiListIndex - 1], roiIdList, isGlobalRoi }));
            }
        }
    }, [dispatch, fullRoiDetails, isGlobalRoi]);

    const handleNextRoi = useCallback(() => {
        if (fullRoiDetails) {
            const { currentRoiId, roiIdList } = fullRoiDetails;
            const currentRoiListIndex = roiIdList.indexOf(currentRoiId);
            if (currentRoiListIndex < roiIdList.length - 1) {
                dispatch(fullRoiDetailsShown({ currentRoiId: roiIdList[currentRoiListIndex + 1], roiIdList, isGlobalRoi }));
            }
        }
    }, [dispatch, fullRoiDetails, isGlobalRoi]);

    const handleCodingSchemeInputChange = useCallback((event: React.FormEvent<HTMLInputElement>, text: string) => {
        if (roi) {
            // try to get 'id' field from the HTML event object that's been injected there by PatternFly but not recognised by specs
            const id: string | undefined = get(event.target, 'id', undefined);
            if (!id) {
                throw new Error('No id field in event');
            }
            dispatch(roiCodingSchemeUpdated({ roiId: roi.id, codingScheme: { hasCodingScheme: true, [id]: text }, isGlobalRoi: isGlobalRoi }));
        }
    }, [dispatch, isGlobalRoi, roi]);

    const handlePhysicalPropertyValueChanged = useCallback((_: unknown, text: string) => {
        if (roi) {
            dispatch(roiPhysicalPropertyUpdated({ roiId: roi.id, physicalPropertyAttribute: undefined, physicalPropertyValue: text, isGlobalRoi: isGlobalRoi }));
        }
    }, [dispatch, isGlobalRoi, roi]);


    const handlePhysicalPropertyAttributeChanged = useCallback((value: PhysicalProperty) => {
        if (roi) { dispatch(roiPhysicalPropertyUpdated({ roiId: roi.id, physicalPropertyAttribute: value, physicalPropertyValue: undefined, isGlobalRoi: isGlobalRoi })); }
    }, [dispatch, isGlobalRoi, roi]);


    const isOpen = fullRoiDetails !== undefined && roi !== undefined;

    const currentIndex = fullRoiDetails ? fullRoiDetails.roiIdList.indexOf(fullRoiDetails.currentRoiId) : -1;
    const hasNext = fullRoiDetails && fullRoiDetails.roiIdList[fullRoiDetails.roiIdList.length - 1] !== fullRoiDetails.currentRoiId;
    const hasPrevious = fullRoiDetails && fullRoiDetails.roiIdList[0] !== fullRoiDetails.currentRoiId;

    const coveredRoisLength = isGlobalRoi ? get(roi, 'coveredRois', ['']).length : -1;

    const hasPhysicalPropertiesValidationError = validationError && validationError.type === CustomizationObjectType.PhysicalProperties;

    const handleCopyCodingSchemeToAllCustomization = (codingScheme: Partial<CodingScheme>) => {
        dispatch(roiCodingSchemeCopied(codingScheme));
    }

    const handleCopyCodingSchemeToCurrentOutput = (codingScheme: Partial<CodingScheme>) => {
        if (roi && isContouringRoi(roi)) {
            dispatch(roiCodingSchemeCopiedToOutput({ codingSchemeChanges: codingScheme, outputId: roi.customizationOutputId }));
        }
    }

    const onCopyFmaCodesClick = (type: CodingSchemeCopyTarget) => {
        if (roi) {
            if (type === CodingSchemeCopyTarget.AllRois) {
                handleCopyCodingSchemeToAllCustomization(extractSharedCodingSchemeAttributes(roi));
            } else if (type === CodingSchemeCopyTarget.WithinOutput) {
                handleCopyCodingSchemeToCurrentOutput(extractSharedCodingSchemeAttributes(roi));
            }
        }
    };

    const handleOpenCopyFmaIdsConfirmationModal = (key: CodingSchemeCopyTarget) => {
        setConfirmationModalKey(key);
        // Set the modal open state
        setCopyFmaIdsConfirmationModalOpen(true);
    }

    const onCopyFmaIdsSelectToggle = (_: unknown, isOpen: boolean) => {
        setCopyFmaIdsSelectOpen(isOpen);
    }

    const onActionSelect = () => {
        setCopyFmaIdsSelectOpen(false);
    };


    return (
        <Modal
            className="full-roi-details-modal"
            tabIndex={0}
            isOpen={isOpen}
            onClose={handleClose}
            title={isGlobalRoi ? t('fullRoiDetails.title.global', { count: coveredRoisLength }) : t('fullRoiDetails.title.regular')}
            variant={ModalVariant.large}
            actions={[
                <Button key="prev" variant="secondary" onClick={handlePreviousRoi} isDisabled={!hasPrevious}>{t('fullRoiDetails.previous')}</Button>,
                <Button key="next" variant="secondary" onClick={handleNextRoi} isDisabled={!hasNext}>{t('fullRoiDetails.next')}</Button>,
                <span key="index" className="list-index">{currentIndex !== -1 && fullRoiDetails && (<span>{currentIndex + 1}/{fullRoiDetails.roiIdList.length}</span>)}</span>,
                <Button key="close" variant="tertiary" onClick={handleClose}>{t('common.close')}</Button>,
            ]}
        >
            {isCopyFmaIdsConfirmationModalOpen && (
                <ConfirmationModal
                    isOpen={isCopyFmaIdsConfirmationModalOpen}
                    title={confirmationModalKey === CodingSchemeCopyTarget.AllRois ? t('fullRoiDetails.copyFmaIdsInUserConfigConfirm.title') : t('fullRoiDetails.copyFmaIdsInOutputConfigConfirm.title')}
                    description={confirmationModalKey === CodingSchemeCopyTarget.AllRois ? t('fullRoiDetails.copyFmaIdsInUserConfigConfirm.message') : t('fullRoiDetails.copyFmaIdsInOutputConfigConfirm.message')}
                    onConfirm={() => {
                        onCopyFmaCodesClick(confirmationModalKey);
                        setCopyFmaIdsConfirmationModalOpen(false);
                    }}
                    onClose={() => setCopyFmaIdsConfirmationModalOpen(false)}
                    variant={ModalVariant.small}
                />
            )}
            {roi && (
                <div>
                    <Table variant='compact'>
                        <Thead aria-label='Full ROI Details'>
                            <Tr>
                                <Td></Td>
                                <Td>{t(columnLangKeys[columnIds.name])}</Td>
                                <Td>{t(columnLangKeys[columnIds.isIncluded])}</Td>
                                <Td>{t(columnLangKeys[columnIds.color])}</Td>
                                <Td>{t(columnLangKeys[columnIds.operation])}</Td>
                                <Td>{t(columnLangKeys[columnIds.interpretedType])}</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <RoiItem key={roi.id} roiId={roi.id} hideIdentificationCodeFields isGlobalRoi={isGlobalRoi} />
                        </Tbody>
                    </Table>

                    <div className="identification-code-table">
                        <Form isHorizontal>
                            <Grid className='fma-grid-gutter'>
                                <GridItem span={8} className='fma-copy-non-highlight'>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-1"
                                            label={t('fullRoiDetails.codingScheme.codeValue')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeCodeValue"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeCodeValue || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-2"
                                            label={t('fullRoiDetails.codingScheme.codeMeaning')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeCodeMeaning"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeCodeMeaning || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                </GridItem>
                                <Grid className='fma-copy-highlight fma-grid-gutter'>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-3"
                                            label={t('fullRoiDetails.codingScheme.codeSchemeDesignator')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeDesignator"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeDesignator || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-4"
                                            label={t('fullRoiDetails.codingScheme.codeSchemeVersion')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeVersion"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeVersion || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-5"
                                            label={t('fullRoiDetails.codingScheme.mappingResource')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeMappingResource"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeMappingResource || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-6"
                                            label={t('fullRoiDetails.codingScheme.contextGroupVersion')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeContextGroupVersion"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeContextGroupVersion || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-7"
                                            label={t('fullRoiDetails.codingScheme.contextIdentifier')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeContextIdentifier"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeContextIdentifier || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-8"
                                            label={t('fullRoiDetails.codingScheme.contextUid')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeContextUid"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeContextUid || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={GRID_SPAN}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-9"
                                            label={t('fullRoiDetails.codingScheme.mappingResourceUid')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeMappingResourceUid"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeMappingResourceUid || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={5}>
                                        <FormGroup
                                            fieldId="field-roi-details-fmaid-10"
                                            label={t('fullRoiDetails.codingScheme.mappingResourceName')}>
                                            <TextInput
                                                type="text"
                                                id="codingSchemeMappingResourceName"
                                                onChange={handleCodingSchemeInputChange}
                                                value={roi.codingSchemeMappingResourceName || ''}
                                            />
                                        </FormGroup>
                                    </GridItem>
                                    <GridItem span={5}>
                                        {isGlobalRoi ? (
                                            <Button
                                                onClick={() => handleOpenCopyFmaIdsConfirmationModal(CodingSchemeCopyTarget.AllRois)}
                                                variant="primary"
                                            >
                                                {t('fullRoiDetails.copyFmaIds.allRois')}
                                            </Button>
                                        ) : (
                                            <Dropdown
                                                onSelect={onActionSelect}
                                                toggle={
                                                    <DropdownToggle
                                                        id="toggle-split-button-action-primary"
                                                        splitButtonItems={[
                                                            <DropdownToggleAction
                                                                key="default-copy-action"
                                                                aria-label="copy-fma-ids-default"
                                                                onClick={(event) => {
                                                                    event.preventDefault(); // Prevent default action
                                                                    handleOpenCopyFmaIdsConfirmationModal(CodingSchemeCopyTarget.AllRois);
                                                                }}
                                                            >
                                                                {t('fullRoiDetails.copyFmaIds.allRois')}
                                                            </DropdownToggleAction>

                                                        ]}
                                                        toggleVariant="primary"
                                                        splitButtonVariant="action"
                                                        onToggle={onCopyFmaIdsSelectToggle}
                                                    />
                                                }
                                                isOpen={isCopyFmaIdsSelectOpen}
                                                dropdownItems={[
                                                    <DropdownItem key={CodingSchemeCopyTarget.AllRois} component="button" onClick={() => handleOpenCopyFmaIdsConfirmationModal(CodingSchemeCopyTarget.AllRois)}>
                                                        {t('fullRoiDetails.copyFmaIds.allRois')}
                                                    </DropdownItem>,
                                                    <DropdownItem key={CodingSchemeCopyTarget.WithinOutput} component="button" onClick={() => handleOpenCopyFmaIdsConfirmationModal(CodingSchemeCopyTarget.WithinOutput)}>
                                                        {t('fullRoiDetails.copyFmaIds.withinOutput')}
                                                    </DropdownItem>
                                                ]}
                                            />
                                        )}
                                    </GridItem>
                                </Grid>
                            </Grid>
                            <Card isFlat className="physical-properties-card-container">
                                <CardTitle>{t('fullRoiDetails.physicalProperties.box.title')}</CardTitle>
                                <CardBody className="physical-properties-card">
                                    <FormGroup label={t('fullRoiDetails.physicalProperties.box.label')} fieldId="field-roi-physical-property-attribute">
                                        <PhysicalPropertiesDropdown
                                            id="physicalPropertyAttribute"
                                            onSelect={handlePhysicalPropertyAttributeChanged}
                                            value={roi.physicalPropertyAttribute || ''} />
                                    </FormGroup>
                                    <FormGroup
                                        label={t('fullRoiDetails.physicalProperties.box.value')}
                                        fieldId="field-roi-physical-property-value">
                                        <TextInput
                                            type="text"
                                            id="physicalPropertyValue"
                                            onChange={handlePhysicalPropertyValueChanged}
                                            value={roi.physicalPropertyValue || ''}
                                        />
                                    </FormGroup>
                                    {hasPhysicalPropertiesValidationError &&
                                        (<div className="roi-item validation-error validation-error-box">
                                            <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                                                {validationError.field && (<div>Field: {validationError.field}</div>)}
                                                <div>Error type: {validationError.detail}</div>
                                                <div>Error ctx: {validationError.ctx}</div>
                                            </Alert>
                                        </div>)}
                                </CardBody>

                            </Card>

                        </Form>
                    </div>

                </div>
            )}
        </Modal>
    );
}

export default FullRoiDetailsModal;
